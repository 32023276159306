<script>
import { defineComponent } from 'vue';
import PrintModal from './PrintModal.vue';
import * as config from '../../config';

export default defineComponent({
  components: {
    PrintModal,
  },
  props: {
    printData: {
      type: Object,
    },
  },
  data() {
    return {
      printGroups: [],
      printSettings: null,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$user?.isAuthenticated || false;
    },
    addPrintText() {
      return this.isAuthenticated
        ? this.$globalTexts.pdp__addprint || 'pdp__addprint'
        : this.$globalTexts.pdp__login_to_addprint || 'pdp__login_to_addprint';
    },
    requestContext() {
      return {
        channelSystemId: this.$channel.systemId,
        websiteSystemId: this.$channel.website?.systemId,
        culture: this.$channel.locale,
      };
    },
    showPrintButton() {
      return this.printGroups?.length && this.printSettings;
    },
  },
  methods: {
    openPrintModal() {
      if (!this.isAuthenticated && !this.$route?.query?.action) {
        const search = this.$route.query || {};
        const query = {
          ...search,
          action: 'add-print',
        };

        this.$navigateToLoginPage(query);
        return;
      }
      this.$refs.printModal.showPrintConfigurationPopup();
    },
    async loadPrintSettings() {
      if (!this.$channel?.systemId) return;

      try {
        const response = await fetch(
          `${config.litiumBaseUrl}/api/productprint/settings`,
          {
            method: 'GET',
            headers: {
              'litium-request-context': JSON.stringify(this.requestContext),
            },
          }
        );
        if (!response.ok) {
          throw Error(response.statusText);
        }

        const data = await response.json();
        this.printSettings = data;
      } catch (error) {
        console.error(error);
      }
    },
    async loadProductPrints() {
      if (!this.$channel?.systemId || !this.printData?.variantSystemId) return;

      try {
        const response = await fetch(
          `${config.litiumBaseUrl}/api/productprint/?variantId=${this.printData.variantSystemId}`,
          {
            method: 'GET',
            headers: {
              'litium-request-context': JSON.stringify(this.requestContext),
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }
        const data = await response.json();

        const printGroups = data;
        printGroups.forEach((printGroup) => {
          if (!printGroup.PrintPositions?.length) return;

          printGroup.PrintPositions.forEach((printPosition) => {
            if (!printPosition?.Images?.length) return;

            printPosition.Images.forEach((image) => {
              image.Url = `${config.litiumBaseUrl}/${image.Url}`;
            });
          });
        });
        this.printGroups = printGroups;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    $channel(newValue, oldValue) {
      if (!newValue.systemId || oldValue?.systemId) return;

      this.loadPrintSettings();
      this.loadProductPrints();
    },
    'printData.variantSystemId': function () {
      this.loadProductPrints();
    },
  },
  async mounted() {
    await this.loadProductPrints();
    await this.loadPrintSettings();

    if (sessionStorage.getItem('add-print-to-cart')) {
      sessionStorage.removeItem('add-print-to-cart');
      this.$nextTick(() => {
        this.openPrintModal();
      });
    }
  },
});
</script>

<template>
  <div
    v-if="showPrintButton"
    class="personalize-product-button"
    @click="openPrintModal"
  >
    <img src="../../static/icons/settings_icon.svg" />
    <span class="personalize-product-button__text">
      {{ addPrintText }}
    </span>
    <print-modal
      ref="printModal"
      :print-data="printData"
      :print-groups="printGroups"
      :print-settings="printSettings"
    />
  </div>
</template>

<style>
.personalize-product-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.personalize-product-button__text {
  font-size: 16px;
  margin: 0 8px;
}
</style>
