<script>
/* eslint-disable no-underscore-dangle */
import { defineComponent, inject } from 'vue';
import TextInput from '../forms/TextInput.vue';

import ModalContainer from '../ModalContainer.vue';
import PersonalizeCalculator from '../PrintModule/PersonalizeCalculator.vue'

export default defineComponent({
  components: {
    ModalContainer,
    PersonalizeCalculator,
    TextInput,
  },
  data() {
    return {
      quantity: 1,
      calculations: [],
      positionForCalculation: '',
    };
  },
  inject: ['$addToCartContextReactive', 'registerModal', 'unregisterModal'],
  computed: {
    selectedVariant() {
      return this.$addToCartContextReactive.selectedVariant;
    },
    availablePrints() {
      return this.selectedVariant.availablePrints;
    },
    allPrintPositionNames() {
      return this.availablePrints
        .map((p) => p.printPositions)
        .reduce((a, b)=> a.concat(b), [])
        .map(p => p.name)
        .filter((v, i, a) => a.indexOf(v) === i);
    },
    hasCalculations() {
      return this.calculations.length != 0;
    },
    hasCalculationsWithAddons() {
      return this.calculations.filter(c=>c.addons.length > 0).length > 0;
    },
    sortedCalculations() {
      var positionNames = this.allPrintPositionNames;
      
      var sortedCalculations = this.calculations.slice(0);
      sortedCalculations.sort((a, b) => positionNames.indexOf(a.position) < positionNames.indexOf(b.position) ? -1 : 1);

      return sortedCalculations
    },
  },
  methods: {
    showCalculator(position) {
      this.positionForCalculation = position;
      this.registerModal('PrintCalculator');
    },
    closeCalculator() {
      this.positionForCalculation = '';
      this.unregisterModal('PrintCalculator');
    },
    addToCalculation(calculation) {
      this.calculations = this.calculations.filter(c=>c.position !== calculation.position);
      this.calculations.push(calculation);

      this.closeCalculator();
    },
    removeCalculation(position) {
      this.calculations = this.calculations.filter(c=>c.position !== position);
    },
    formatPositionTitle(position) {
      var matchingCalculations = this.calculations.filter(c=>c.position === position);
      if(matchingCalculations.length == 0) {
        return position;
      }
      
      return `${position}: ${matchingCalculations[0].method}/${matchingCalculations[0].type}`;
    },
    formatCalculationTitle(calculation) {
      return `${calculation.method}/${calculation.type}`;
    },
    calculationPrice(calculation) {
      var brackets = calculation.brackets.filter(b => b.minimumQuantity <= this.quantity);
      var bracket = brackets.length != 0 ? brackets[brackets.length - 1] : calculation.brackets[0];

      return bracket.price;
    },    
    formatAddonTitle(addon) {
      if(addon.quantity > 1) {
        return `${addon.name} (${this.$formatPrice(addon.unformattedPrice)} x ${addon.quantity})`;
      }

      return addon.name;
    },
    partialCost(calculation) {
      var brackets = calculation.brackets.filter(b => b.minimumQuantity <= this.quantity);
      var bracket = brackets.length != 0 ? brackets[brackets.length - 1] : calculation.brackets[0];
      
      var price = bracket.price;
      for(var i=0; i<calculation.addons.length; i++) {
        price += calculation.addons[i].unformattedPrice * calculation.addons[i].quantity;
      }

      return price;
    },
    printTotalCost() {
      var total = 0;
      
      for(var i = 0; i< this.calculations.length; i++) {
        var calculation = this.calculations[i];

        var brackets = calculation.brackets.filter(b => b.minimumQuantity <= this.quantity);
        var bracket = brackets.length != 0 ? brackets[brackets.length - 1] : calculation.brackets[0];
        total += bracket.price * this.quantity;

        for(var j=0; j<calculation.addons.length; j++) {
            total += calculation.addons[j].unformattedPrice * calculation.addons[j].quantity;
        }
      }

      return total;
    },
    grandTotalPrice() {
      return this.selectedVariant.price * this.quantity + this.printTotalCost();
    },
    isPositionSelected(position) {
      return this.calculations.filter(c=>c.position == position).length != 0;
    }
  }
});
</script>

<template>
  <div class="product-detail-tabs-personalization">
    <div class="product-detail-tabs-personalization__calculator">
      <div class="product-detail-tabs-personalization__title">
        {{ $globalTexts.personalization__calculator_title }}
      </div>

      <div class="product-detail-tabs-personalization__subtitle product-detail-tabs-personalization__subtitle-info" 
            v-if="!hasCalculations">
            {{ $globalTexts.personalization__calculator_pick_location }}
      </div>

      <div class="product-detail-tabs-personalization__positions">
        <div class="product-detail-tabs-personalization__position" 
              :class="{'product-detail-tabs-personalization__position-selected': isPositionSelected(positionName) }"
              v-for="positionName in allPrintPositionNames" :key="positionName" >
              
              <span 
                class="product-detail-tabs-personalization__position-title"
                @click="showCalculator(positionName)"> 
                {{formatPositionTitle(positionName)}}
              </span>
              
              <img
                v-if="!isPositionSelected(positionName)"
                src="../../static/icons/right_arrow_icon.svg"
                alt="select"
                class="product-detail-tabs-personalization__position-image-select"
                @click="showCalculator(positionName)"
              >

              <img
                v-if="isPositionSelected(positionName)"
                src="../../static/icons/cancel_white_icon.svg"
                alt="remove"
                class="product-detail-tabs-personalization__position-image-remove"
                @click="removeCalculation(positionName)"
              >
        </div>
      </div>

      <div class="product-detail-tabs-personalization__line-container" v-if="hasCalculations">
        <span class="product-detail-tabs-personalization__text-small">
          {{ $globalTexts.personalization__calculator_products }} 
        </span>

        <text-input
          class="product-detail-tabs-personalization__input"
          type="number"
          v-model="quantity"
          :min="1"
          :autoselect="true"
        />
      </div>

      <div class="product-detail-tabs-personalization__line-container" v-if="hasCalculations">
        <div class="product-detail-tabs-personalization__line">
          <div class="product-detail-tabs-personalization__item">{{ $globalTexts.personalization__calculator_item_price }}</div>
          <div class="product-detail-tabs-personalization__price">{{ $formatPrice(selectedVariant.price)}} / {{ $globalTexts.personalization__calculator_unit }}</div>
        </div>
      </div>

      <div
          v-for="calculation in sortedCalculations"
          :key="calculation.position">
        <div class="product-detail-tabs-personalization__line-container">
          <div class="product-detail-tabs-personalization__line">
            <div class="product-detail-tabs-personalization__position-name">
              {{calculation.position}}            
            </div>
          </div>
          <div class="product-detail-tabs-personalization__line">
            <div class="product-detail-tabs-personalization__subtitle-small"> {{ formatCalculationTitle(calculation) }} </div>
            <div class="product-detail-tabs-personalization__subtitle-small" :key="quantity" >+{{ $formatPrice(calculationPrice(calculation)) }}</div>
          </div>

          <div class="product-detail-tabs-personalization__line" v-for="addon in calculation.addons" :key="addon.id">          
            <div class="product-detail-tabs-personalization__subtitle-small"> {{ formatAddonTitle(addon) }} </div>
            <div class="product-detail-tabs-personalization__subtitle-small">+{{ $formatPrice(addon.unformattedPrice * addon.quantity) }}</div>
          </div>

          <div class="product-detail-tabs-personalization__line">
            <div class="product-detail-tabs-personalization__subtitle-subtle"> {{ $globalTexts.personalization__calculator_partial_cost }} </div>
            <div class="product-detail-tabs-personalization__subtitle-subtle" :key="quantity" >+{{ $formatPrice(partialCost(calculation)) }}</div>
          </div>
        </div>
      </div>

      <div 
        v-if="hasCalculationsWithAddons"
        class="product-detail-tabs-personalization__note"
      >
        <span class="product-detail-tabs-personalization__text-small">{{ $globalTexts.personalization__calculator_stencil }}</span>
      </div>

      <div class="product-detail-tabs-personalization__total-line" v-if="hasCalculations">
        <div class="product-detail-tabs-personalization__line">
          <div class="product-detail-tabs-personalization__subtitle-small">{{ $globalTexts.personalization__calculator_product_total }}</div>
          <div class="product-detail-tabs-personalization__subtitle-small">{{ $formatPrice(selectedVariant.price * quantity) }}</div>
        </div>

        <div class="product-detail-tabs-personalization__line" v-if="hasCalculations">
          <div class="product-detail-tabs-personalization__subtitle-small">{{ $globalTexts.personalization__calculator_print_total }}</div>
          <div class="product-detail-tabs-personalization__subtitle-small">{{ $formatPrice(printTotalCost()) }}</div>
        </div>

        <div class="product-detail-tabs-personalization__line">
          <div class="product-detail-tabs-personalization__total">{{ $globalTexts.personalization__calculator_total_price }}</div>
          <div class="product-detail-tabs-personalization__total">{{ $formatPrice(grandTotalPrice()) }}</div>
        </div>    
      </div>
    </div>
    
    <div class="product-detail-tabs-personalization__description" v-html="$globalTexts.personalization__calculator_description"></div>

    <modal-container
      class="product-detail-tabs-personalization__modal"
      position="center"
      @close-modal="closeCalculator"
      @navigate-back="closeCalculator"
      :show-exit-button="true"
      :show-back-button="true"
      :show-left-exit-button="false"
      v-if="positionForCalculation"
    >
      <personalize-calculator
        :position='positionForCalculation'
        @add-to-calculation='addToCalculation'
      >
      </personalize-calculator>
    </modal-container>
  </div>
</template>

<style>
  .product-detail-tabs-personalization {
    display: flex;
    flex-direction: column;
    font-style: normal;
  }

  .product-detail-tabs-personalization__calculator {
    width: 100%;
    margin-bottom: 40px;
    border: 1px solid var(--color-neutrals-20);
    border-radius: 10px;
    padding: 10px;
  }

  .product-detail-tabs-personalization__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-black);
    margin-bottom: 22px;
  }

  .product-detail-tabs-personalization__subtitle {
    font-size: 14px;
    line-height: 26px;
    color: var(--color-black);
    margin-bottom: 10px;
  }

  .product-detail-tabs-personalization__subtitle-info {
    color: #5379ff;
  }

  .product-detail-tabs-personalization__positions {
    margin: 0 -10px 30px;
    padding: 0 10px;
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE & Edge */
    scrollbar-width: none; /* Firefox */
    flex-wrap: wrap;
  }

  .product-detail-tabs-personalization__positions::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  .product-detail-tabs-personalization__position {
    scroll-snap-align: center;
    white-space: nowrap;
    border: 1px solid #6D6D6D;    
    display: flex;
    padding: 4px 16px;
    border-radius: 20px;
    font-size: 14px;
    letter-spacing: .59px;
    column-gap: 10px;
    line-height: 20px;
  }

  .product-detail-tabs-personalization__position-title {
    cursor: pointer;
  }

  .product-detail-tabs-personalization__position-image-select {
    height: 18px;
    padding-top: 4px;
    cursor: pointer;
  }

  .product-detail-tabs-personalization__position-image-remove {
    height: 16px;
    padding-top: 6px;
    cursor: pointer;
  }

  .product-detail-tabs-personalization__position-selected {
    color: var(--color-neutrals-00);
    background-color: var(--color-neutrals-100);
  }

 .product-detail-tabs-personalization__line-container {
     margin-bottom: 9px;
  }

  .product-detail-tabs-personalization__line {
    display: flex;
    justify-content: space-between;
    color: var(--color-black);
  }

  .product-detail-tabs-personalization__total-line {
    display: flex;
    justify-content: space-between;
    padding-top: 11px;    
    color: var(--color-text-darker);
    flex-flow: column;
    border-top: 1px solid var(--color-neutrals-20);
  }

  .product-detail-tabs-personalization__text-small {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: var(--color-text-darker);
    margin-bottom: 6px;
    display: inline-block;
  }

  .product-detail-tabs-personalization__input input{
    font-size: 16px;
    line-height: 24px;
    width: 122px;
    -moz-appearance: textfield;
  }

  .product-detail-tabs-personalization__input input::-webkit-outer-spin-button,
  .product-detail-tabs-personalization__input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .product-detail-tabs-personalization__price,
  .product-detail-tabs-personalization__item {
    font-size: 14px;
    line-height: 26px;
    color: var(--color-text-darker);
  }

  
  .product-detail-tabs-personalization__position-name {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    color: var(--color-text-darker);
  }

  .product-detail-tabs-personalization__subtitle-small {
    font-size: 14px;
    font-style: italic;
    line-height: 24px;
    color: var(--color-text-darker);    
  }

  .product-detail-tabs-personalization__subtitle-subtle {
    font-size: 14px;
    font-style: italic;
    line-height: 24px;
    color: var(--color-neutrals-80);
  }

  .product-detail-tabs-personalization__note {
    border-top: 1px solid var(--color-neutrals-20);    
    padding: 20px 0;
  }

  .product-detail-tabs-personalization__total {
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
  }

  .product-detail-tabs-personalization__modal .modal-container.modal-container--center {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }

  .product-detail-tabs-personalization__modal .modal-container__exit-button {
    display: none;
  }

  .product-detail-tabs-personalization__description {
    width: 100%;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  

  @media (--tabletAndDesktop) {
    .product-detail-tabs-personalization {
      flex-direction: row;
      margin-bottom: 40px;
    }

    .product-detail-tabs-personalization__calculator {
      width: 50%;
      margin-bottom: 0px;
      padding: 40px;
    }

    .product-detail-tabs-personalization__title {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 22px;
    }

    .product-detail-tabs-personalization__subtitle {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }

    .product-detail-tabs-personalization__positions {
      gap: 10px;
      margin: 0 -10px 36px
    }

    .product-detail-tabs-personalization__line-container {
      margin-bottom: 18px;
    }

    .product-detail-tabs-personalization__total-line {
      padding-top: 18px;
    }

    .product-detail-tabs-personalization__subtitle-small {
      font-size: 14px;
      line-height: 24px;
    }

    .product-detail-tabs-personalization__item,
    .product-detail-tabs-personalization__price {
      font-size: 16px;
      line-height: 24px;
    }

    .product-detail-tabs-personalization__modal .modal-container__back-button {
      display: none;
    }

    .product-detail-tabs-personalization__modal .modal-container__exit-button {
      display: initial;
    }

    .product-detail-tabs-personalization__description {
      width: 50%;
      padding: 40px;
    }
  }
</style>
