<script>
import { defineComponent } from "vue";
import ModalContainer from "../ModalContainer.vue";

export default defineComponent({
  components: {
    ModalContainer,
  },
  emits: ["close"],
  inject: ["$addToCartContextReactive", "registerModal", "unregisterModal"],
  computed: {
    product() {
      return this.$addToCartContextReactive.product;
    },
    productColors() {
      return this.$addToCartContextReactive.productColors;
    },
    selectedColor() {
      return this.$variantToColorString(
        this.$addToCartContextReactive.selectedVariant
      );
    },
    selectedColorName() {
      return (
        this.$addToCartContextReactive.selectedVariant?.fields.Color?.map(
          (c) => c.name
        )
          .sort()
          .join("/") || ""
      );
    },
    availableColorsText() {
      return this.$replaceTokens(this.$globalTexts.global__available_colors, {
        count: this.productColors.length,
      });
    },
    selectedColorText() {
      return `${this.$globalTexts.pdp__selected_color} ${this.selectedColorName}`;
    },
  },
  methods: {
    getColorName(colorVariant) {
      return colorVariant.fields.Color.map((c) => c.name)
        .sort()
        .join("/");
    },
    isSelectedVariant(colorVariant) {
      return this.$isColorVariantMatch(this.selectedColor, colorVariant);
    },
    getImageUrl(colorVariant) {
      return this.$toLitiumMediaUrl(colorVariant.images[0].id, {
        maxWidth: 79,
      });
    },
    close() {
      this.unregisterModal("ColorDialogPicker");
      this.$emit("close");
    },
  },
  mounted() {
    this.registerModal("ColorDialogPicker");
  },
});
</script>

<template>
  <teleport to="body">
    <modal-container
      @close-modal="close"
      position="center"
      class="color-picker-dialog__modal"
    >
      <div class="color-picker-dialog">
        <div class="color-picker-dialog__header">
          <div class="color-picker-dialog__available-colors">
            {{ availableColorsText }}
          </div>
          <div class="color-picker-dialog__selected-colors">
            {{ selectedColorText }}
          </div>
          <div class="color-picker-dialog__close" @click="close()">
            <img
              class="color-picker-dialog__exit-button"
              src="../../static/icons/cancel_icon.svg"
            />
          </div>
        </div>
        <div class="color-picker-dialog__colors">
          <router-link
            class="color-picker-dialog__color"
            v-for="colorVariant in productColors"
            :key="colorVariant.systemId"
            :to="$toVariantUrl(colorVariant)"
            @click="close()"
          >
            <div class="color-picker-dialog__color-content">
              <div class="color-picker-dialog__spacer"></div>
              <div class="color-picker-dialog__overlay"></div>
              <img
                draggable="false"
                class="color-picker-dialog__variant"
                :class="{
                  'color-picker-dialog__variant--selected':
                    isSelectedVariant(colorVariant),
                }"
                :src="getImageUrl(colorVariant)"
              />
            </div>
          </router-link>
        </div>
      </div>
    </modal-container>
  </teleport>
</template>

<style>
.color-picker-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: unset;
  flex-direction: column;
  margin: 0.5rem;
}

.color-picker-dialog__header {
  position: relative;
  max-width: 563px;
  text-align: center;
  width: 100%;
  padding-top: 2.5rem;
}

.color-picker-dialog__available-colors {
  font-size: 24px;
  font-weight: 700;
}

.color-picker-dialog__selected-colors {
  font-size: 14px;
  padding-top: 0.75rem;
  padding-bottom: 1.25rem;
  color: #1a1a1a;
}

.color-picker-dialog__colors {
  max-width: calc(563px + 10px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% + 10px);
}

.color-picker-dialog__color {
  display: block;
  position: relative;
  width: calc(25% - 10px);
  margin: 5px;
}

.color-picker-dialog__spacer {
  padding-top: 124%;
}

.color-picker-dialog__overlay {
  z-index: 50;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.color-picker-dialog__variant {
  border: 1px solid transparent;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: auto;
}

.color-picker-dialog__variant--selected {
  border: 1px solid black;
}

.color-picker-dialog__close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #333333;
}

@media (--tabletAndDesktop) {
  .color-picker-dialog {
    justify-content: center;
    margin: 0;
  }

  .color-picker-dialog__header {
    padding-top: 0;
  }

  .color-picker-dialog__selected-colors {
    padding-bottom: 2.5rem;
  }

  .color-picker-dialog__color {
    width: calc(16.66% - 10px);
  }

  .color-picker-dialog__close {
    top: -5px;
  }
}
</style>
