<script>
  import { fetchPageBuilder, OptionsComponent } from '@drapejs/core';

  import {validateEmail} from '../../utils.ts'
  import * as vingaSales from '../../../connectors/litium/sales';
  import TextInput from '../forms/TextInput.vue';

  export default {
    extends: OptionsComponent,
    props: {
      articleNumber: {
        type: String,
        default: "text",
      },
    },
    components: {
      TextInput,
    },
    data() {
      return {
        email: '',
        emailValidationError: null,
        subscriptionMessage: null,
        showSubscribeButton: true
      };
    },
    methods: {
      clearError() {
        this.emailValidationError = null;
        this.subscriptionMessage = null;
      },
      async signup() {
        if (!validateEmail(this.email)) {
          this.emailValidationError = this.$globalTexts.pdp__monitorproductinvalidemail;
          return;
        }

        const result = await this.addBackInStockAlert();
        if (result.error == 'NONE') {
          this.showSubscribeButton = false;
          this.subscriptionMessage = this.$globalTexts.pdp__monitorproductsubscribed.replace('{email}', this.email);
        } else {
          this.subscriptionMessage = this.$globalTexts.pdp__monitorproducterror;
        }
      },
      async addBackInStockAlert() {
        const request = this.buildRequest();
        request.email = this.email;
        request.variantId = this.articleNumber;

        return await this.$invoke(vingaSales.commands.addBackInStockAlert, request);
      },
      buildRequest() {
        return fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          {
            ...this.$route.query,
          },
          '',
        );
      },
    },
  }
</script>

<template>
  <div class="back-in-stock-signup">

    <div class="back-in-stock-signup__header">
      <h4>{{ $globalTexts.pdp__monitorproduct }}</h4>
      <div>{{ $globalTexts.pdp__monitorproductformsubtitle }}</div>
    </div>

    <div class="back-in-stock-signup__body">
      <div class="back-in-stock-signup__body-email">
        <div>
          <span class="back-in-stock-signup__label">{{ $globalTexts.pdp__monitorproductemail }}</span>
        </div>

        <text-input
          v-model="email"
          type="text"
          :error-message="emailValidationError"
          @focus="clearError"
        />
      </div>

      <div
        v-if="subscriptionMessage"
        class="back-in-stock-signup__body-message"
      >
        {{ subscriptionMessage }}
      </div>

      <div class="back-in-stock-signup__body-submit">
        <button
          v-if="showSubscribeButton"
          class="primary-button back-in-stock-signup__body-submit-button"
          @click="signup()"
        >
          {{ $globalTexts.pdp__monitorproductbutton }}
        </button>
      </div>
    </div>

  </div>
</template>

<style>
  .back-in-stock-signup {
    margin: auto;
    width: 100%;
    max-width: 460px;
    padding: 2rem;
  }

  .back-in-stock-signup__header {
    text-align: center;
    font-size: 18px;
    margin: 0;
  }

  .back-in-stock-signup__body {
    margin: 2rem 0;
  }

  .back-in-stock-signup__label {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0;
    display: block;
    text-align: center;
  }

  .back-in-stock-signup__body-message {
    margin-top: 1rem;
    font-size: 14px;
    font-weight: 500;
  }

  .back-in-stock-signup__body-submit {
    margin-top: 1rem;
  }

  .back-in-stock-signup__body-submit-button {
    width: 100%;
  }

  @media (--phoneAndTablet) {
    .back-in-stock-signup {
      margin-top: 4rem;
    }

    .back-in-stock-signup__header {
      margin: 0 2rem;
    }

    .back-in-stock-signup__body {
      margin: 2rem;
    }

    .back-in-stock-signup__body-submit-button {
      height: 45px;
    }      
  }  
</style>
