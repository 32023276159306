<script>
/* eslint-disable no-underscore-dangle */
import { defineComponent } from "vue";

import ProductDetailTabsPersonalization from './ProductDetailTabsPersonalization'

export default defineComponent({
  components: {
    ProductDetailTabsPersonalization,
  },
  inject: ["$addToCartContextReactive"],
  data: () => {
    return {
        selectedTab: 'personalization',
        currentTabComponent: 'product-detail-tabs-personalization'
    };
  },
  computed: {
    selectedVariant() {
      return this.$addToCartContextReactive.selectedVariant;
    },
    hasPrints() {
      return this.selectedVariant?.availablePrints && this.selectedVariant.availablePrints.length != 0;
    }
  },
  methods: {
    updateContent(tab) {
      this.selectedTab = tab;

      switch(tab) {
        case 'personalization':
          this.currentTabComponent = 'product-detail-tabs-personalization';
          break;
      }
    }
  },
});
</script>

<template>
  <div class="product-detail-tabs" v-if="hasPrints">
    <div class="product-detail-tabs__container">
      <div class="product-detail-tabs__title" @click="updateContent('personalization')">
        {{ $globalTexts.personalization__calculator_tab_title }}
      </div>
    </div>

    <div class="product-detail-tabs__content">
      <component :is="currentTabComponent"></component>
    </div>
  </div>
</template>

<style>
.product-detail-tabs {
  display: flex;
  max-width: calc(var(--header-max-width) + 30px);
  padding: 0 10px 40px;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.product-detail-tabs__container{
  display: flex;
  overflow: auto;
  border-bottom: 1px solid #CDCDCD;
}

.product-detail-tabs__title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-right: 20px;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--color-neutrals-100);
}

.product-detail-tabs__content {
  max-width: calc(var(--header-max-width) + 30px);
  padding-top: 25px;
}
</style>
