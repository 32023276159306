<script>
import { defineComponent } from "vue";
import AddToCart from "../AddToCart.vue";
import ModalContainer from "../ModalContainer.vue";
import LinkButton from "../forms/LinkButton.vue";

const inStock = 1;
const incomingStock = 2;
const outOfStock = 3;

export default defineComponent({
  components: {
    AddToCart,
    ModalContainer,
    LinkButton,
  },
  inject: ["$addToCartContextReactive"],
  props: {
    stock: {
      type: Object,
      required: true,
    },
  },
  computed: {
    stockStatus() {
      if (this.stock.currentStock.amount > 0) {
        return inStock;
      }

      if (this.stock.incomingStock.some((s) => s.amount > 0)) {
        return incomingStock;
      }
      return outOfStock;
    },
    indicatorColorVariant() {
      let color = null;

      switch (this.stockStatus) {
        case inStock:
          color = "in";
          break;
        case incomingStock:
          color = "incoming";
          break;
        case outOfStock:
          color = "out";
          break;
        default:
          return null;
      }

      return `product-overview-stock-status__indicator--${color}`;
    },
  },
});
</script>

<template>
  <div
    class="product-overview-stock-status"
    @click="
      $addToCartContextReactive.openAddToCartModal({
        title: $globalTexts.global__available_stock,
        isShowStockRequest: true,
      })
    "
  >
    <span
      class="product-overview-stock-status__indicator"
      :class="indicatorColorVariant"
    ></span>

    <link-button type="arrow">{{
      $globalTexts.global__open_stock_details_label
    }}</link-button>
  </div>
</template>

<style>
.product-overview-stock-status {
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 22px;
  margin-bottom: 12px;
  font-size: 14px;
}

.product-overview-stock-status__indicator {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 6px;
}

.product-overview-stock-status__arrow {
  height: 24px;
  margin-left: 5px;
}

.product-overview-stock-status__indicator--out {
  background-color: #a72424;
}

.product-overview-stock-status__indicator--incoming {
  background-color: #d8ac33;
}

.product-overview-stock-status__indicator--in {
  background-color: #459a33;
}
</style>
