<script>
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['$addToCartContextReactive'],
  computed: {
    specsToDisplay() {
      return [
        {
          id: 'articleNumber__',
          value: this.articleNumber,
          name: this.$globalTexts.global__label_article_number
        },
        ...this.certifications,
        ...this.techSpecs
      ]
        .filter((spec) => spec.value)
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    },
    techSpecs() {
      return (
        this.$channel?.techSpecFields?.map((f) => {
          let value = this.getFieldValueWithFallback(f.id);
          if (value && f.type === 'TextOption') {
            value = [value]
              .flat()
              .map((v) => v.name)
              .sort()
              .join('/');
          }
          return {
            name: f.name,
            id: f.id,
            value
          };
        }) || []
      );
    },
    certifications() {
      const certifications =
        this.$channel?.certificationFields
          ?.map((f) => ({
            name: f.name,
            id: f.id,
            value: this.getFieldValueWithFallback(f.id)
          }))
          .filter((v) => v.value)
          .map((v) => v.name) || [];

      return [
        {
          id: 'certifications__',
          name: this.$globalTexts.global__label_certifications,
          value: certifications.join(', ')
        }
      ];
    },
    articleNumber() {
      return this.selectedVariant?.articleNumber;
    },
    selectedVariant() {
      return this.$addToCartContextReactive.selectedVariant;
    },
    product() {
      return this.$addToCartContextReactive.product;
    }
  },
  methods: {
    getFieldValueWithFallback(fieldId) {
      let value = this.selectedVariant.fields[fieldId];
      if (value === null || typeof value === 'undefined') {
        value = this.product.fields[fieldId];
      }
      return value;
    }
  }
});
</script>

<template>
  <div class="product-details__tech">
    <div class="product-details__specs">
      <div
        class="product-details__spec"
        v-for="spec in specsToDisplay"
        :key="spec.id"
      >
        <div class="product-details__spec-name">{{ spec.name }}</div>
        <div class="product-details__spec-value">{{ spec.value }}</div>
      </div>
    </div>
  </div>
</template>

<style>
.product-details__specs {
  display: flex;
  flex-wrap: wrap;
}

.product-details__spec {
  width: 50%;
  color: #1a1a1a;
  margin-bottom: 34px;
}

.product-details__spec-name {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.product-details__spec-value {
  font-size: 16px;
}

@media (--tabletAndDesktop) {
  .product-details__spec {
    width: 25%;
  }

  .product-details__spec-name {
    margin-bottom: 12px;
  }
}

@media (max-width: 1200px) and (min-width: 769px) {
  .product-details__spec {
    width: 50%;
  }
}

@media (--tablet) {
  .product-details__tech {
    width: 50%;
    max-width: 50%;
    padding: 0 15px;
    padding-top: 35px;
  }
}

@media (--desktop) {
  .product-details__tech {
    width: 100%;
  }
}
</style>
