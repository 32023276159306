<script>

export default {
  props: {
    originalPrice: {
      type: Number,
      required: true,
    },
    campaignPrice: {
      type: Number,
    },
    locale: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "",
    },
    verticalStack: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fixPrice(price) {     
      return this.$formatPrice(Math.floor(price));
    },
  }
};
</script>

<template>
  <div :class="[ 'display-price', 
    {'display-price--vertical': verticalStack}]"
  >
    <div 
      v-if="campaignPrice" 
      class="display-price__campaign-price"
    >
      {{fixPrice(campaignPrice)}}
    </div>
    <div :class="['display-price__original-price', 
      {'display-price__original-price--disabled' : campaignPrice}]"
    >
      {{fixPrice(originalPrice)}}
    </div>
  </div>
</template>

<style>
  .display-price {
    display: inline-flex;
  }

  .display-price--vertical {
    flex-direction: column;
  }

  .display-price__campaign-price {
    color: red;
    margin-right: .7rem;
  }

  .display-price__original-price {
    position: relative;
  }

  .display-price__original-price--disabled {
    color: var(--color-wool-grey);
  }

  .display-price__original-price--disabled::before {
    content: "";
    border-bottom: 1px solid var(--color-wool-grey);
    position: absolute;
    top: 55%;
    left: -2px;
    right: -2px;
  }
</style>