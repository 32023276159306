<script>
  import { OptionsComponent } from '@drapejs/core';

  export default {
    extends: OptionsComponent,
    inject: {
      $addToCartContextReactive: '$addToCartContextReactive',
    }
  }
</script>

<template>
  <div class="back-in-stock">
      <button 
        class="primary-button back-in-stock__button" 
        @click="$addToCartContextReactive.openBackInStockModal()" >
        {{ $globalTexts.pdp__monitorproduct }}
      </button>
  </div>
</template>

<style>
  .back-in-stock {
    width: 100%;
  }

  .back-in-stock__button {
   width: 100%;
  }
</style>
