<script>
import { h } from 'vue';

export default {
  props: {
    inset: {
      type: Number,
      default: 0,
    },
    fixedHeight: {
      type: Number,
      default: 0,
    },
    initialSlide: {
      type: Number,
      default: 1,
    },
    keyPrefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      width: 0,
      height: this.fixedHeight,
      virtualPosition: 0,
      friction: 0.05,
      slides: [],
      initialized: false,
      slideRefs: [],
    };
  },
  emits: ['slide-did-change'],
  mounted() {
    this.initialized = false;
    this.loadSlides();
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateSize);
      this.updateSize();
      if (this.$refs.container) {
        this.$refs.container.addEventListener('touchstart', this.touchStart);
        this.$refs.container.addEventListener('touchmove', this.touchMove);
        this.$refs.container.addEventListener('touchend', this.touchEnd);
        this.$refs.container.addEventListener('touchcancel', this.touchEnd);
        this.$refs.container.addEventListener('load', this.contentLoaded, true);
      }
      this.$emit('slide-did-change', this.currentSlide);
    });
  },
  beforeUpdate() {
    this.slideRefs = [];
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateSize);
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('touchstart', this.touchStart);
      this.$refs.container.removeEventListener('touchmove', this.touchMove);
      this.$refs.container.removeEventListener('touchend', this.touchEnd);
      this.$refs.container.removeEventListener('touchcancel', this.touchEnd);
      this.$refs.container.removeEventListener('load', this.contentLoaded);
    }
  },
  watch: {
    width() {
      this.scrollToSlidePos(this.virtualPosition);
    },
    currentSlide(value) {
      this.$emit('slide-did-change', value);
    },
  },
  methods: {
    reset() {
      this.virtualPosition = 0;
    },
    updateSize() {
      if (!this.$refs.container) {
        if (!this.updatingSize) {
          this.updatingSize = true;
          this.$nextTick(() => {
            this.updateSize();
          });
        }

        return;
      }

      this.updateWidth();
      this.$nextTick(() => {
        this.updateHeight();
        this.updatingSize = false;
      });
    },
    updateWidth() {
      // Math.ceil is used to make sure slides outside of view doesn't round up with a pixel inside the frame
      this.width = Math.ceil(this.$refs.container.getBoundingClientRect().width);
    },
    updateHeight() {
      if (this.fixedHeight) return;
      const slideHeights = this.slideRefs.map((slide) => slide.getBoundingClientRect().height);
      this.height = Math.round(Math.max(...slideHeights) || this.height);
    },
    touchStart(e) {
      if (this.isFrozen) return;

      this.lastTouchX = e.touches[0].screenX;
      this.lastTouchY = e.touches[0].screenY;
      this.deltaX = 0;
      this.deltaY = 0;
      this.startPosition = this.virtualPosition;
      this.touchLastUpdate = performance.now();
      this.touchVelocity = 0;
      this.touchIgnore = false;
      this.touching = true;
    },
    touchEnd() {
      if (this.touchIgnore || this.isFrozen) {
        return;
      }

      this.touchLock = false;
      this.breakDownTime = this.touchLastUpdate;
      this.touchVelocity *= this.friction + 1;
      this.touching = false;
      requestAnimationFrame(this.breakDown);
    },
    scrollToSlidePos(position) {
      if (this.touching) return;
      const target = this.indexAndPositionAt(position + (this.slideToDistanceLeft || 0));
      this.slideToTargetPosition = (target.position * this.contentWidth) - ((this.width - this.contentWidth) / 2);

      if (!this.initialized) {
        this.virtualPosition = this.slideToTargetPosition;
        if (this.initialSlide !== this.currentSlide) {
          this.go(this.initialSlide - this.currentSlide);
          return;
        }
        this.initialized = true;
        return;
      }

      this.slideToDistanceLeft = this.slideToTargetPosition - this.virtualPosition;

      let processFrame = (t) => {
        if (this.touching) return;

        if (Math.abs(this.slideToDistanceLeft) < 1) {
          this.virtualPosition = this.slideToTargetPosition;
          this.slideToRunning = false;
          this.slideToDistanceLeft = 0;
          return;
        }

        const distance = this.slideToDistanceLeft * 0.15;
        this.slideToDistanceLeft -= distance;
        this.virtualPosition += distance;
        requestAnimationFrame(processFrame);
      };
      processFrame = processFrame.bind(this);
      if (!this.slideToRunning) {
        requestAnimationFrame(processFrame);
      }
    },
    breakDown(time) {
      if (this.touching) return;

      if (Math.abs(this.touchVelocity) <= 200) {
        this.scrollToSlidePos(this.virtualPosition);
        return;
      }
      this.virtualPosition -= (time - this.breakDownTime) * this.touchVelocity / 1000;
      this.touchVelocity *= 1 - this.friction;

      this.breakDownTime = time;
      requestAnimationFrame(this.breakDown);
    },
    touchMove(e) {
      if (this.touchIgnore || this.isFrozen || e.touches.length > 1) {
        return;
      }

      const xDeltaSinceLast = e.touches[0].screenX - this.lastTouchX;
      this.deltaX -= e.touches[0].screenX - this.lastTouchX;
      this.deltaY -= e.touches[0].screenY - this.lastTouchY;
      this.touchVelocity = xDeltaSinceLast / (performance.now() - this.touchLastUpdate) * 1000;
      this.touchLastUpdate = performance.now();
      this.lastTouchX = e.touches[0].screenX;
      this.lastTouchY = e.touches[0].screenY;

      if (!this.touchLock) {
        this.touchLock = Math.abs(this.deltaY) < 20 && Math.abs(this.deltaX) > 10;
      }

      if (this.touchLock) {
        if ((typeof e.cancelable !== 'boolean' || e.cancelable)) {
          e.preventDefault();
        } else {
          this.touchIgnore = Math.abs(this.deltaY) > 20;
          if (this.touchIgnore) {
            this.touching = false;
            this.scrollToSlidePos(this.virtualPosition);
            return;
          }
        }
      }

      this.virtualPosition = this.startPosition + this.deltaX;
    },
    go(delta) {
      if (this.isFrozen) return;

      this.scrollToSlidePos(this.virtualPosition + (this.contentWidth * delta));
    },
    slideAtDelta(delta, virtualPosition) {
      return this.slideInViewsFromIndexPositionAndVirtualPosition(
        this.indexAndPositionAt(virtualPosition + delta),
        virtualPosition,
      );
    },
    slideInViewsFromIndexPositionAndVirtualPosition(indexAndPosition, virtualPosition) {
      let pos = (indexAndPosition.position * this.contentWidth) - virtualPosition;
      if (pos < -this.width) {
        pos = -this.width;
      }
      if (pos > this.width) {
        pos = this.width;
      }
      return {
        e: this.slides[indexAndPosition.index],
        pos: `${pos}px`,
        virtualPos: indexAndPosition.position,
      };
    },
    indexAndPositionAt(virtualPosition) {
      const position = Math.round((virtualPosition + ((this.width - this.contentWidth) / 2)) / this.contentWidth);
      let index = Math.floor(position % this.slides.length);

      if (index < 0) {
        index = this.slides.length + index;
      }

      return {
        index,
        position,
      };
    },
    loadSlides() {
      this.$nextTick(() => {
        this.slides = this.$slots.default()[0].children || [];
        this.$nextTick(() => {
          this.updateHeight();
        });
      });
    },
    contentLoaded(e) {
      this.$nextTick(() => {
        this.updateHeight();
      });
    },
    setSlideRef(el) {
      if (el) {
        this.slideRefs.push(el);
      }
    },
    goToSlide(targetSlide) {
      this.go(targetSlide - this.currentSlide);
    },
  },
  computed: {
    visibleSlides() {
      if (!this.width) {
        return this.slides.map((e, i) => this.slideInViewsFromIndexPositionAndVirtualPosition({ index: i, position: i }, 0));
      }
      if (this.isFrozen) return [this.slideAtDelta(0, this.virtualPosition)];

      return [
        this.slideAtDelta(-this.contentWidth * 2, this.virtualPosition),
        this.slideAtDelta(-this.contentWidth, this.virtualPosition),
        this.slideAtDelta(0, this.virtualPosition),
        this.slideAtDelta(+this.contentWidth, this.virtualPosition),
        this.slideAtDelta(+this.contentWidth * 2, this.virtualPosition),
      ];
    },
    contentWidth() {
      return this.width * (100 - this.inset) / 100;
    },
    currentSlideIndex() {
      return this.indexAndPositionAt(this.virtualPosition);
    },
    currentSlide() {
      return this.currentSlideIndex.index + 1;
    },
    isFrozen() {
      return this.slides.length < 2;
    },
  },
  render() {
    const content = this.visibleSlides.reduce((res, slide) => {
      const key = this.keyPrefix + slide.virtualPos + this.initialized;

      let arrPos = slide.virtualPos % 5;
      if (arrPos < 0) {
        arrPos += 5;
      }
      const attrs = {};
      if (slide.virtualPos == this.currentSlideIndex.position) {
        attrs['data-current-slide'] = true;
      }

      res[arrPos] = h('div', {
        class: 'content-carousel__slide',
        key,
        ref: this.setSlideRef,
        refInFor: true,
        ...attrs,
        style: {
          transform: `translateX(${slide.pos})`,
          width: `${100 - this.inset}%`,
        },
      },
      [slide.e]);

      return res;
    }, [])?.filter((slide) => slide);

    if (this.$slots.overlay) {
      content.push(h('div', {
        class: 'content-carousel__overlay',
        key: 'overlay',
      }, this.$slots.overlay()));
    }

    const container = h('div', {
      class: 'content-carousel',
      key: 'container',
      ref: 'container',
      style: { height: `${this.height}px` },
    }, content);

    return h('div', {
      key: 'root',
    }, [
      container,
    ]);
  },
};
</script>

<style>
.content-carousel {
  overflow:hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}

.content-carousel__slide {
  position: absolute;
  top: 0;
  display: inline-block;
}

.content-carousel__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}
</style>
