<script>
/* eslint-disable no-param-reassign */
import { defineComponent } from 'vue';
import { fetchPageBuilder } from '@drapejs/core';
import QuantityInput from './QuantityInput.vue';
import Checkbox from './forms/Checkbox.vue';
import BackInStock from './BackInStock/BackInStock.vue';
import { useCartFunctions } from '../helpers/cart-actions';

import * as vingaSales from '../../connectors/litium/sales.ts';

export default defineComponent({
  setup() {
    return { ...useCartFunctions() };
  },
  components: {
    QuantityInput,
    Checkbox,
    BackInStock,
  },
  inject: ['$addToCartContextReactive'],
  emits: ['added-to-cart'],
  props: {
    title: {
      type: String,
      default: '',
    },
    selectedQuantity: {
      type: Number,
      default: 1,
    },
    selectedPrintData: {
      type: [Object, null],
    },
  },
  data() {
    return {
      quantity: this.selectedQuantity,
      upsellItems: [],
      error: '',
    };
  },
  mounted() {
    const upsellItems = this.product?.upsellItems;

    if (this.product?.upsellItems) {
      this.upsellItems = upsellItems.map((i) => {
        const obj = { ...i };
        i.isActive = false;
        return obj;
      });
    }
  },
  computed: {
    isAuthenticated() {
      return this.$user?.isAuthenticated || false;
    },
    selectedVariant() {
      return this.$addToCartContextReactive.selectedVariant;
    },
    colorSizes() {
      return this.$addToCartContextReactive.colorSizes;
    },
    product() {
      return this.$addToCartContextReactive.product;
    },
    unitOfMeasurement() {
      return this.selectedVariant?.unitOfMeasurement;
    },
    packQuantity() {
      return this.selectedVariant?.packaging?.packQuantity || 1;
    },
    packagingInfo() {
      return this.selectedVariant?.packaging?.caption || '';
    },
    allStockItems() {
      const stockItems = [...this.incomingStockItems] || [];

      if (this.currentStock) {
        stockItems.unshift(this.currentStock);
      }

      stockItems.sort((a, b) => (a.date > b.date) - (a.date < b.date));

      return stockItems;
    },
    incomingStockItems() {
      const stock = (this.selectedVariant?.stock?.incomingStock || []).map(
        (s) => {
          const obj = { ...s };
          const date = new Date(obj.date);
          obj.stockLabel = this.$formatDate(date, 'yyyy-MM-dd');
          obj.deliveryLabel = `Available from: ${obj.stockLabel}`;

          return obj;
        }
      );
      return stock;
    },
    currentStock() {
      if (!this.selectedVariant) return null;

      const stock = {
        ...this.selectedVariant?.stock?.currentStock,
        stockLabel: this.$globalTexts.global__in_stock,
        deliveryLabel: this.$globalTexts.global__available_today,
      };
      return stock;
    },
    activeAddons() {
      return this.upsellItems.filter((i) => i.isActive);
    },
    totalProduct() {
      return this.selectedVariant.price * this.quantity;
    },
    totalAddons() {
      return this.activeAddons.reduce((p, i) => p + i.totalUpsellRow, 0);
    },
    printUnitPrice() {
      if (!this.selectedPrintData?.priceBrackets) return null;

      return [...this.selectedPrintData.priceBrackets]
        .sort((a, b) => b.MinimumQuantity - a.MinimumQuantity)
        .find((p) => this.quantity >= p.MinimumQuantity)?.Price;
    },
    totalPrintPrice() {
      return (this.printUnitPrice || 0) * this.quantity;
    },
    total() {
      return (
        (this.selectedVariant.price + this.totalAddons) * this.quantity +
        this.totalPrintPrice +
        this.totalPrintAddons
      );
    },
    printAddons() {
      return this.selectedPrintData?.printAddons || [];
    },
    totalPrintAddons() {
      return this.printAddons.reduce((p, i) => p + i.Price * i.Quantity, 0);
    },
    hidePrices() {
      const hidePrices = this.$channel?.hidePricesForAnonymousUsers || false;
      return hidePrices ? !this.$user?.isAuthenticated : false;
    },
    isVariantOutOfStock() {
      if (this.selectedVariant.stock.currentStock.amount > 0) {
        return false;
      }

      if (this.selectedVariant.stock.incomingStock.some((s) => s.amount > 0)) {
        return false;
      }

      return true;
    },
  },
  methods: {
    redirectToLogin() {
      const search = this.$route.query || {};
      const query = {
        ...search,
        action: 'add',
      };
      this.$navigateToLoginPage(query);
    },
    async addToCart() {
      const articleNumber = this.selectedVariant?.articleNumber;

      if (!articleNumber) return;

      if (!this.isAuthenticated) {
        this.redirectToLogin();
        return;
      }

      const variantData = {
        articleNumber,
        variantSystemId: this.selectedVariant.systemId,
        quantity: this.quantity,
      };

      const { row, error } = await this.addItemToCart(
        variantData,
        this.selectedPrintData
      );

      if (error !== 'NONE') {
        if (error === 'NOT_ENOUGH_STOCK') {
          this.error = this.$replaceTokens(
            this.$globalTexts.cart__available_stock,
            {
              amount: `${
                this.allStockItems[this.allStockItems.length - 1].amount
              }`,
            }
          );
        } else if (error === 'NOT_AUTHENTICATED') {
          this.redirectToLogin();
        } else {
          this.error = error;
        }
        return;
      }

      if (row) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.activeAddons) {
          this.addUpsellItem(row, item);
        }
      }

      this.$emit('added-to-cart', row?.id || null);
    },
    async addUpsellItem(row, upsellItem) {
      const request = this.buildRequest();

      request.rowSystemId = row.id;
      request.variantSystemId = row.productId;
      request.upsellItemRowSystemId = upsellItem.rowSystemId;
      request.upsellItemVariantSystemId = upsellItem.variantSystemId;
      request.isUpsellItem = true;
      request.quantity = row.quantity;

      await this.$invoke(vingaSales.commands.setCartRowUpsellProduct, request);
    },
    buildRequest() {
      return fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
        },
        ''
      );
    },
    getUpsellItemText(upsellItem) {
      const variantAndPrice = `${upsellItem.text?.toLowerCase()} (${this.$formatPrice(
        upsellItem.value
      )})`;
      const result = this.$replaceTokens(this.$globalTexts.global__add_upsell, {
        variant: variantAndPrice,
      });
      return result;
    },
    getUpsellItemPrice(upsellItem) {
      if (upsellItem.isActive) {
        return this.$formatPrice(upsellItem.totalUpsellRow * this.quantity);
      }
      return '-';
    },
  },
});
</script>

<template>
  <div class="add-to-cart">
    <div class="add-to-cart__header">
      {{ title }}
    </div>
    <div class="add-to-cart__content-wrap">
      <div>
        <div class="add-to-cart__label">
          {{ $globalTexts.global__quantity }}:
        </div>
        <quantity-input
          :quantity="+quantity"
          :unitOfMeasurement="unitOfMeasurement"
          :stock-items="allStockItems"
          :narrow-style="true"
          :pack-quantity="packQuantity"
          :packaging-info="packagingInfo"
          :error="error"
          @update:quantity="quantity = $event"
        />
      </div>
      <div class="add-to-cart__divider" />
      <div class="add-to-cart__pricing" v-if="!hidePrices">
        <div class="add-to-cart__pricing-item">
          <div>
            {{ $globalTexts.global__product }}
            <span v-if="selectedVariant"
              >({{ $formatPrice(selectedVariant.price || 0) }})</span
            >
          </div>
          <div>{{ $formatPrice(totalProduct) }}</div>
        </div>
        <div v-if="printUnitPrice" class="add-to-cart__pricing-item">
          <div>
            {{ $globalTexts.global__label_print }} ({{
              $formatPrice(printUnitPrice)
            }})
          </div>
          <div>{{ $formatPrice(totalPrintPrice) }}</div>
        </div>
        <div
          v-for="printAddon in printAddons"
          :key="printAddon.VariantSystemId"
          class="add-to-cart__pricing-item"
        >
          <div>
            {{ printAddon.Name }}
          </div>
          <div>{{ $formatPrice(printAddon.Price * printAddon.Quantity) }}</div>
        </div>
        <div
          v-for="upsellItem in upsellItems"
          :key="upsellItem.variantSystemId"
          class="add-to-cart__pricing-item"
        >
          <checkbox
            :text="getUpsellItemText(upsellItem)"
            v-model="upsellItem.isActive"
          />
          <span>{{ getUpsellItemPrice(upsellItem) }}</span>
        </div>
        <div class="add-to-cart__pricing-item cart-item__subtotal">
          <div>{{ $globalTexts.global__subtotal }}</div>
          <div>{{ $formatPrice(total) }}</div>
        </div>
      </div>
    </div>
    <div class="add-to-cart__bottom">
      <div class="add-to-cart__total" v-if="!hidePrices">
        <div>
          {{ $globalTexts.cart__total }}
        </div>
        <div>
          {{ $formatPrice(total) }}
        </div>
      </div>
      <button
        class="primary-button"
        v-if="!isVariantOutOfStock"
        @click="addToCart()"
      >
        {{ $globalTexts.global__add_to_cart }}
      </button>
      <back-in-stock v-if="isVariantOutOfStock" />
    </div>
  </div>
</template>

<style>
.add-to-cart {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
}

.add-to-cart__header {
  text-align: center;
  font-weight: 700;
  line-height: 24px;
  margin-top: 12px;
}

.add-to-cart__label {
  font-size: 14px;
}

.add-to-cart__content-wrap {
  background-color: white;
  margin: 0;
  box-shadow: none;
  padding: 10px 20px 20px;
}

.add-to-cart__pricing {
  margin-top: 0.25rem;
}

.add-to-cart__divider {
  margin: 0 -10px;
  border-top: 1px solid #f2f2f2;
}

.add-to-cart__pricing-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  color: #1a1a1a;
}

.cart-item__subtotal {
  font-weight: 700;
  color: #1a1a1a;
}

.add-to-cart__bottom {
  margin-top: auto;
  background-color: var(--color-neutrals-00);
  padding: 10px 20px 10px;
}

.add-to-cart__total {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 10px;
}

.add-to-cart__bottom .primary-button {
  width: 100%;
}
</style>
