export function renderPrintPreview(canvasContext, printPosition, printSettings, printType, selectedPrintImageUrl, selectedPrintText, postRenderFunction) {
    canvasContext.clearRect(0, 0, canvasContext.canvas.width, canvasContext.canvas.height);
    var background = new Image();

    background.onload = () => {
        drawBackground(canvasContext, background);
        drawPrint(background, canvasContext, printPosition, printSettings, printType, selectedPrintImageUrl, selectedPrintText, postRenderFunction);
    };

    background.crossOrigin = "Anonymous";
    background.src = printPosition.Url;
}

function drawBackground(canvasContext, background) {
    var backgroundPosition = getAbsolutePixelPositionFromRelativePercentagePosition(background, canvasContext.canvas, 50, 50, 100, 100);
    canvasContext.drawImage(background, backgroundPosition.x, backgroundPosition.y, backgroundPosition.width, backgroundPosition.height);
}

function drawPrint(background, canvasContext, printPosition, printSettings, printType, selectedPrintImageUrl, selectedPrintText, postRenderFunction) {
    var position = getAbsolutePixelPositionFromRelativePercentagePosition(background, canvasContext.canvas, printPosition.X, printPosition.Y, printPosition.Width, printPosition.Height);

    if (printPosition.Rotation != 0 && (selectedPrintImageUrl || printSettings.PlaceholderImageUrl)) {
        rotateCanvas(canvasContext, position, printPosition.Rotation);
    }

    if (printType == 'Image') {
        drawImage(selectedPrintImageUrl || printSettings.PlaceholderImageUrl, canvasContext, position, postRenderFunction);
    }
    else if (printType == 'Text') {
        drawText(selectedPrintText || printSettings.PlaceholderText, canvasContext, position, postRenderFunction);
    }
    else {
        console.error('Unknown print type selected.');
    }
}

function getAbsolutePixelPositionFromRelativePercentagePosition(image, container, xPercentage, yPercentage, widthPercentage, heightPercentage) {
    var imageBounds = getImageBoundsForAspectRatio(image, container);

    var width = (imageBounds.width / 100) * widthPercentage;
    var height = (imageBounds.height / 100) * heightPercentage;
    var x = imageBounds.x + ((imageBounds.width / 100) * xPercentage) - (width * 0.5);
    var y = imageBounds.y + ((imageBounds.height / 100) * yPercentage) - (height * 0.5);

    return {
        x: x,
        y: y,
        width: width,
        height: height
    };
}

function rotateCanvas(canvasContext, position, rotation) {
    var centerOfRotationX = position.x + (position.width * 0.5);
    var centerOfRotationY = position.y + (position.height * 0.5);

    canvasContext.translate(centerOfRotationX, centerOfRotationY);
    canvasContext.rotate(rotation * Math.PI / 180);
    canvasContext.translate(-centerOfRotationX, -centerOfRotationY);
}

function drawImage(imageUrl, canvasContext, position, postRenderFunction) {
    var image = new Image();

    image.onload = () => {
        var imageBounds = getImageBoundsForAspectRatio(image, position);
        canvasContext.drawImage(image, position.x + imageBounds.x, position.y + imageBounds.y, imageBounds.width, imageBounds.height);
        canvasContext.resetTransform();
        postRenderFunction();
    };
    image.crossOrigin = "Anonymous";
    image.src = imageUrl;
}

function drawText(text, canvasContext, position, postRenderFunction) {
    canvasContext.fillStyle = '#FFF';
    var fontFace = "Verdana";
    var fontSize = position.height * 0.8;//Compensation to get body height.
    canvasContext.font = fontSize + "px " + fontFace;
    canvasContext.textBaseline = 'middle';
    canvasContext.textAlign = 'center';

    do {
        fontSize--;
        canvasContext.font = fontSize + "px " + fontFace;
    } while (canvasContext.measureText(text).width > position.width);

    canvasContext.fillText(text, position.x + (position.width * 0.5), position.y + (position.height * 0.5), position.width);
    canvasContext.resetTransform();
    postRenderFunction();
}

function getImageBoundsForAspectRatio(image, container) {
    var ratio = container.width / image.naturalWidth;

    if (image.naturalHeight * ratio > container.height) {
        ratio = container.height / image.naturalHeight;
    }

    var width = image.naturalWidth * ratio;
    var height = image.naturalHeight * ratio;
    var x = (container.width - width) * 0.5;
    var y = (container.height - height) * 0.5;

    return {
        x: x,
        y: y,
        width: width,
        height: height
    };
}