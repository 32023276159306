<script>
import { defineComponent } from "vue";
import ColorPickerDialog from "./ColorPickerDialog.vue";

export default defineComponent({
  components: { ColorPickerDialog },
  inject: ["$addToCartContextReactive"],
  data() {
    return {
      isPickerDialogOpen: false,
    };
  },
  computed: {
    product() {
      return this.$addToCartContextReactive.product;
    },
    productColors() {
      return this.$addToCartContextReactive.productColors;
    },
    firstFourProductColors() {
      return this.productColors.slice(0, 4);
    },
    selectedColor() {
      return this.$variantToColorString(
        this.$addToCartContextReactive.selectedVariant
      );
    },
    selectedColorName() {
      return (
        this.$addToCartContextReactive.selectedVariant?.fields.Color?.map(
          (c) => c.name
        )
          .sort()
          .join("/") || ""
      );
    },
    seeAllText() {
      return this.$replaceTokens(this.$globalTexts.global__see_all_colors, {
        count: this.productColors.length,
      });
    },
  },
  methods: {
    getColorName(colorVariant) {
      return colorVariant.fields.Color.map((c) => c.name)
        .sort()
        .join("/");
    },
    isSelectedVariant(colorVariant) {
      return this.$isColorVariantMatch(this.selectedColor, colorVariant);
    },
    getImageUrl(colorVariant) {
      return this.$toLitiumMediaUrl(colorVariant.images[0].id, {
        maxWidth: 77,
      });
    },
  },
});
</script>

<template>
  <div class="color-picker">
    <div class="color-picker__name">{{ selectedColorName }}</div>
    <div class="color-picker__colors">
      <router-link
        v-for="colorVariant in firstFourProductColors"
        :key="colorVariant.systemId"
        class="color-picker__color-link"
        :to="$toVariantUrl(colorVariant)"
      >
        <div class="color-picker__color-image-container">
          <div class="color-picker__color-image-spacer"></div>
          <div class="color-picker__color-image-overlay"></div>
          <img
            draggable="false"
            class="color-picker__color-image"
            :class="{
              'color-picker__color-image--selected':
                isSelectedVariant(colorVariant),
            }"
            :src="getImageUrl(colorVariant)"
          />
        </div>
      </router-link>
    </div>
    <div
      class="color-picker__more"
      :class="{ 'color-picker__more--active': productColors.length > 4 }"
      @click="isPickerDialogOpen = true"
    >
      {{ seeAllText }}
    </div>
    <color-picker-dialog
      v-if="isPickerDialogOpen"
      @close="isPickerDialogOpen = false"
      class="color-picker__dialog"
      :class="{ 'color-picker__dialog--active': isPickerDialogOpen }"
    />
  </div>
</template>

<style>
.color-picker__name {
  color: #6d6d8e;
}

.color-picker__colors {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 6px 0;
}

.color-picker__color-link {
  flex-grow: 1;
  margin-right: 10px;
  max-width: 79px;
  position: relative;
}

.color-picker__color-image-container {
  position: relative;
}

.color-picker__color-image-spacer {
  padding-top: 124%;
}

.color-picker__color-image-overlay {
  z-index: 50;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.color-picker__color-image {
  border: 1px solid transparent;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: auto;
}

.color-picker__color-image--selected {
  border: 1px solid black;
}

.color-picker__more {
  visibility: hidden;
}

.color-picker__more--active {
  visibility: visible;
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.color-picker__dialog {
  pointer-events: none;
  opacity: 0;
  transition: opacity linear 100ms;
}

.color-picker__dialog--active {
  pointer-events: all;
  opacity: 1;
}

@media(--tabletAndDesktop){
  .color-picker__more--active {
    padding-bottom: 0;
  }
}
</style>
