<script>
import { defineComponent } from 'vue';
import * as config from '../../config';
import { renderPrintPreview } from './print-utils';
import TextInput from '../forms/TextInput.vue';

const PrintConfigStates = {
  hidden: 'hidden',
  selectPrintVariant: 'selectPrintVariant',
  selectPosition: 'selectPosition',
  selectImage: 'selectImage',
};

export default defineComponent({
  inject: [
    'registerModal',
    'unregisterModal',
    '$addToCartContextReactive',
  ],
  components: {
    TextInput,
  },
  props: {
    printData: {
      type: Object,
    },
    printGroups: {
      type: Array,
    },
    printSettings: {
      type: Object,
    },
  },
  data() {
    const dataVals = {
      currentPrintConfigState: PrintConfigStates.hidden,
      printConfigStateBeforeWindowClose: null,
      selectedPrintPosition: this.printGroups.length > 0 ? this.printGroups[0].PrintPositions[0] : null,
      isPrintConfigurationInitiated: false,
      selectedPrintDataSourceType: this.printGroups.length > 0 ? this.printGroups[0].PrintTypes[0].DataSource : '',
      selectedPrintGroup: this.printGroups.length > 0 ? this.printGroups[0] : null,
      selectedPrintType: null,
      selectedPrintImage: null,
      productPrintsErrorMessage: null,
      selectedPrintText: null,
      selectedPrintTextPreview: null,
      lastPrintPreviewDataUrl: null,
      printImages: [],
      selectedQuantity: 1,
      minQuantity: 1,
    };

    if (dataVals.selectedPrintGroup.PrintTypes.filter((t) => t.DataSource === dataVals.selectedPrintDataSourceType).length > 0) {
      dataVals.selectedPrintType = dataVals.selectedPrintGroup.PrintTypes.filter((t) => t.DataSource === dataVals.selectedPrintDataSourceType)[0];
    }

    return dataVals;
  },
  computed: {
    requestContext() {
      return {
        channelSystemId: this.$channel.systemId,
        websiteSystemId: this.$channel.website?.systemId,
        culture: this.$channel.locale,
      };
    },
    mandatoryPrintAddons() {
      return this.selectedPrintType?.PrintAddOns?.filter((p) => p.Mandatory) || [];
    },
  },
  methods: {
    hidePrintConfigurationPopup(e) {
      if (e.target === e.currentTarget) {
        this.printConfigStateBeforeWindowClose = this.currentPrintConfigState;
        this.currentPrintConfigState = 'hidden';
      }
    },
    showPrintConfigurationPopup() {
      if (this.printConfigStateBeforeWindowClose != null) {
        this.currentPrintConfigState = this.printConfigStateBeforeWindowClose;
        this.printConfigStateBeforeWindowClose = null;
      } else {
        this.currentPrintConfigState = PrintConfigStates.selectPrintVariant;
      }

      this.isPrintConfigurationInitiated = true;
    },
    isPrintDataSourceTypeDisabled(type) {
      return this.printGroups.filter((printGroup) => printGroup.PrintTypes.filter((printType) => printType.DataSource === type).length > 0).length === 0;
    },
    isPrintDataSourceTypeSelected(type) {
      return this.selectedPrintDataSourceType === type;
    },
    selectPrintDataSourceType(type) {
      if (!this.isPrintDataSourceTypeDisabled(type)) {
        this.selectedPrintDataSourceType = type;
      }
    },
    printGroupHasVariantsOfSelectedDataSourceType(printGroup) {
      return printGroup.PrintTypes.filter((t) => t.DataSource === this.selectedPrintDataSourceType).length > 0;
    },
    populateImages() {
      fetch(`${config.litiumBaseUrl}/api/productprint`, {
        method: 'GET',
        headers: {
          'litium-request-context': JSON.stringify(this.requestContext),
        },
      }).then((response) => response.json())
        .then((data) => {
          this.loadPrintImages(data);
        });
    },
    showMediaPopup() {
      this.currentPrintConfigState = PrintConfigStates.selectImage;
      this.populateImages();
    },
    hideMediaPopup(e) {
      if (e.target === e.currentTarget) {
        this.currentPrintConfigState = PrintConfigStates.selectPosition;
      }
    },
    chooseImage(image) {
      this.selectedPrintImage = image;
      this.currentPrintConfigState = PrintConfigStates.selectPosition;
    },
    removePrint(confirmationMessage) {
      if (confirmationMessage && !confirm(confirmationMessage)) {
        return;
      }

      this.resetImageAndTextSelections();

      this.currentPrintConfigState = PrintConfigStates.hidden;
      this.isPrintConfigurationInitiated = false;
      this.lastPrintPreviewDataUrl = null;
      this.printConfigStateBeforeWindowClose = null;

      if (this.printGroups && this.printGroups.length > 0) {
        [this.selectedPrintGroup] = this.printGroups;
        this.selectedPrintDataSourceType = this.selectedPrintGroup.PrintTypes[0].DataSource;
      }
    },
    resetImageAndTextSelections() {
      this.selectedPrintImage = null;
      this.selectedPrintText = null;
      this.selectedPrintTextPreview = null;
    },
    deleteImage(imageId) {
      if (!this.$channel?.systemId) return;

      fetch(`${config.litiumBaseUrl}/api/productprint?fileSystemId=${imageId}`, {
        method: 'DELETE',
        headers: {
          'litium-request-context': JSON.stringify(this.requestContext),
        },
      }).then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response.json();
      }).then((data) => {
        this.loadPrintImages(data);
      }).catch((error) => {
        this.productPrintsErrorMessage = error;
      });
    },
    onFileUploadChanged(e) {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      const formData = new FormData();
      formData.append('file', e.target.files[0]);

      fetch(`${config.litiumBaseUrl}/api/productprint`, {
        method: 'POST',
        body: formData,
        headers: {
          'litium-request-context': JSON.stringify(this.requestContext),
        },
      }).then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response.json();
      }).then((data) => {
        this.loadPrintImages(data);
      }).catch((error) => {
        this.productPrintsErrorMessage = error;
      });
    },
    tryRenderPrintPreviewAndSaveResultIfCompleted() {
      const isInStateToRender = this.currentPrintConfigState === PrintConfigStates.selectPosition && this.selectedPrintPosition !== null && this.selectedPrintPosition.Images.length > 0;
      const vm = this;

      if (isInStateToRender) {
        if (this.$refs.previewCanvas) {
          const canvasContext = this.$refs.previewCanvas.getContext('2d');
          const printPosition = this.selectedPrintPosition.Images[0];
          const { printSettings } = this;
          const printType = this.selectedPrintType.DataSource;
          const selectedPrintImageUrl = this.selectedPrintImage === null ? null : this.selectedPrintImage.Url;
          const selectedPrintText = this.selectedPrintText === '' ? null : this.selectedPrintText;
          const postRenderFunction = function () {
            if (selectedPrintImageUrl || selectedPrintText) {
              vm.lastPrintPreviewDataUrl = vm.$refs.previewCanvas.toDataURL()
                .replace(/^(data:image\/png;base64,)/, ''); // Not needed by NAV.
            }
          };

          renderPrintPreview(canvasContext, printPosition, printSettings, printType, selectedPrintImageUrl, selectedPrintText, postRenderFunction);
        } else {
          setTimeout(() => {
            vm.tryRenderPrintPreviewAndSaveResultIfCompleted();
          }, 100);
        }
      }
    },
    loadPrintImages(data) {
      this.printImages = data;
      this.printImages.forEach((printImage) => {
        printImage.Url = `${config.litiumBaseUrl}${printImage.Url}?maxWidth=400`;
        printImage.ThumbnailUrl = `${config.litiumBaseUrl}${printImage.ThumbnailUrl}?maxWidth=400`;
      });
    },
    selectPrint(e) {
      let printAddOns = [];
      if (this.selectedPrintType != null && this.selectedPrintType.PrintAddOns.length > 0) {
        printAddOns = this.selectedPrintType.PrintAddOns
          .filter((addon) => addon.IsSelected === true || addon.Mandatory)
          .map((addon) => ({
            VariantSystemId: addon.SystemId,
            Quantity: addon.Quantity,
            Price: addon.UnformattedPrice,
            Name: addon.Name,
            OnlyOnePerProductGroup: addon.Mandatory
          }));
      }

      this.$addToCartContextReactive.openAddToCartModal({
        quantity: this.selectedQuantity,
        printData: {
          printVariantSystemId: this.selectedPrintType != null ? this.selectedPrintType.SystemId : '',
          printImageSystemId: this.selectedPrintImage != null ? this.selectedPrintImage.SystemId : '',
          printText: this.selectedPrintText ? this.selectedPrintText.trim() : null,
          printAddons: printAddOns,
          printPosition: this.selectedPrintPosition ? this.selectedPrintPosition.UniqueId : null,
          printPreviewDataUrl: this.lastPrintPreviewDataUrl,
          priceBrackets: this.selectedPrintType != null ? this.selectedPrintType.PriceBrackets : [],
        },
      });

      this.hidePrintConfigurationPopup(e);
    },
  },
  watch: {
    printGroups() {
      this.selectedPrintGroup = this.printGroups.length > 0 ? this.printGroups[0] : null;
      this.selectedPrintPosition = this.printGroups.length > 0 ? this.printGroups[0].PrintPositions[0] : null;
      this.selectedPrintDataSourceType = this.printGroups.length > 0 ? this.printGroups[0].PrintTypes[0].DataSource : '';
      this.selectedPrintType = null;
      this.printConfigStateBeforeWindowClose = null;
    },
    currentPrintConfigState(newState, oldState) {
      function isTransitioningBackToSelectPrintVariant() {
        return newState === PrintConfigStates.selectPrintVariant && oldState === PrintConfigStates.selectPosition;
      }

      if (isTransitioningBackToSelectPrintVariant()) {
        this.resetImageAndTextSelections();
      }

      if (newState !== PrintConfigStates.hidden) {
        this.registerModal('PrintModal');
      } else {
        this.unregisterModal('PrintModal');
      }

      if (newState === PrintConfigStates.selectPosition) {
        this.tryRenderPrintPreviewAndSaveResultIfCompleted();
      }
    },
    selectedPrintDataSourceType() {
      const that = this;

      function selectFirstGroupHavingVariantsOfSelectedDataSourceType() {
        for (let i = 0; i < that.printGroups.length; i++) {
          if (that.printGroupHasVariantsOfSelectedDataSourceType(that.printGroups[i])) {
            that.selectedPrintGroup = that.printGroups[i];
            return;
          }
        }
      }

      if (this.printGroupHasVariantsOfSelectedDataSourceType(this.selectedPrintGroup)) {
        this.selectedPrintType = this.selectedPrintGroup.PrintTypes.filter((t) => t.DataSource === this.selectedPrintDataSourceType)[0];
        return;
      }

      selectFirstGroupHavingVariantsOfSelectedDataSourceType();
    },
    selectedPrintGroup() {
      if (this.selectedPrintGroup == null) {
        this.selectedPrintType = null;
        this.resetImageAndTextSelections();
        return;
      }

      this.selectedPrintType = this.selectedPrintGroup.PrintTypes.filter((t) => t.DataSource === this.selectedPrintDataSourceType)[0];

      if (this.selectedPrintGroup.PrintPositions.length > 0) {
        this.selectedPrintPosition = this.selectedPrintGroup.PrintPositions[0];
        this.tryRenderPrintPreviewAndSaveResultIfCompleted();
      }
    },
    selectedPrintType() {
      if (this.selectedPrintType == null) {
        return;
      }

      this.minQuantity = this.selectedPrintType.MinimumQuantity;
      this.selectedQuantity = this.selectedQuantity < this.minQuantity ? this.minQuantity : this.selectedQuantity;

      if (this.selectedPrintImage != null && this.selectedPrintType.DataSource !== 'Image') {
        this.selectedPrintImage = null;
      }

      if (this.selectedPrintText != null && this.selectedPrintType.DataSource !== 'Text') {
        this.selectedPrintText = null;
      }

      this.tryRenderPrintPreviewAndSaveResultIfCompleted();
    },
    selectedPrintPosition() {
      this.tryRenderPrintPreviewAndSaveResultIfCompleted();
    },
    selectedPrintText() {
      setTimeout(() => {
        this.selectedPrintTextPreview = this.selectedPrintText;
      }, 100);
    },
    selectedPrintTextPreview() {
      this.tryRenderPrintPreviewAndSaveResultIfCompleted();
    },
  },
  beforeUnmount() {
    this.unregisterModal('PrintModal');
  },
});
</script>

<template>
  <teleport to="body">
    <div class="_productDetails-printsPopup" v-if="currentPrintConfigState !== 'hidden'" @click="hidePrintConfigurationPopup">

        <div class="_productDetails-printsPopupContent" v-if="currentPrintConfigState !== 'hidden' && currentPrintConfigState !== 'selectImage'">
            <div class="_productDetails-printsPopupHeading">
                <span class="_productDetails-printsCancel" @click="hidePrintConfigurationPopup">×</span>
            </div>

            <div class="_layout-flex">

                <div class="_productDetails-printsPreviewImageContainer">

                    <img :src="printData.imageUrl" v-if="currentPrintConfigState === 'selectPrintVariant' || selectedPrintPosition === null" style="max-height: 750px;max-width: 100%;"/>

                    <div class="_productDetails-printPositionImageContainer" v-if="currentPrintConfigState === 'selectPosition' && selectedPrintPosition !== null && selectedPrintPosition.Images.length > 0">
                        <canvas ref="previewCanvas" width="500" height="750" class="_productDetails-printPositionImage"></canvas>
                    </div>
                </div>

                <div class="_productDetails-printsSettingsContainer" v-if="currentPrintConfigState === 'selectPrintVariant'">

                    <div class="_layout-flex _productDetails-printsWrapper">
                        <a class="_productDetails-printDataSourceType" :class="{'_productDetails-printSelectedDataSourceType' : isPrintDataSourceTypeSelected('Image'), '_productDetails-printDisabledDataSourceType' : isPrintDataSourceTypeDisabled('Image')}" @click="selectPrintDataSourceType('Image')">{{ $globalTexts.productdetailspage_printdatasourceimage }}</a>
                        <a class="_productDetails-printDataSourceType" :class="{'_productDetails-printSelectedDataSourceType' : isPrintDataSourceTypeSelected('Text'), '_productDetails-printDisabledDataSourceType' : isPrintDataSourceTypeDisabled('Text')}" @click="selectPrintDataSourceType('Text')">{{ $globalTexts.productdetailspage_printdatasourcetext }}</a>
                    </div>

                    <div class="_productDetails-printsWrapper">
                        <div class="_productDetails-printsTitle">
                            {{ $globalTexts.productdetailspage_selectprintmethod }}
                        </div>

                        <div v-for="printGroup in printGroups" :key="printGroup.Id">
                          <label class="print-radio-button">
                            <span
                              class="print-radio-button__label"
                              :for="printGroup.Id" 
                              :disabled="!printGroupHasVariantsOfSelectedDataSourceType(printGroup)">
                              {{printGroup.Name}}
                            </span>
                            <input
                              class="print-radio-button__input"
                              type="radio" name="printGroup" 
                              :id="printGroup.Id" 
                              :disabled="!printGroupHasVariantsOfSelectedDataSourceType(printGroup)" 
                              :checked="selectedPrintGroup == printGroup" 
                              @click="selectedPrintGroup = printGroup"
                            />
                            <span class="print-radio-button__checkmark">
                              <span class="print-radio-button__checkmark-icon"></span>
                            </span>
                          </label>
                        </div>
                    </div>

                    <div class="_productDetails-printsWrapper" v-if="selectedPrintGroup.PrintTypes.length > 1">
                        <div class="_productDetails-printsTitle">
                            {{ $globalTexts.productdetailspage_selectprintvariant }}
                        </div>

                        <select v-model="selectedPrintType" class="_productDetails-printsVariantSelect">
                            <option v-for="printType in selectedPrintGroup.PrintTypes.filter(t=>t.DataSource === selectedPrintDataSourceType)" :value="printType" :key="printType">{{printType.Name}}</option>
                        </select>
                    </div>

                    <div class="_productDetails-printsWrapper" v-if="selectedPrintGroup != null">
                        <div class="_productDetails-printsTitle">
                            {{ $globalTexts.productdetailspage_printprices}}
                        </div>

                        <table class="_productDetails-printsPrices">
                            <thead class="_productDetails-printsPriceHeader">
                                <tr>
                                    <th>{{ $globalTexts.common__quantity }}</th>
                                    <template v-for="printType in selectedPrintGroup.PrintTypes">
                                        <th>{{printType.Name}}</th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="_productDetails-printsPriceRow" v-for="priceBracket in selectedPrintGroup.PriceBrackets" :key="priceBracket.Name">
                                    <td>{{priceBracket.Name}}</td>
                                    <td v-for="price in priceBracket.FormattedPrices" :key="price">{{price}}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="_productDetails-printMandatoryAddonText">
                            <div v-for="printAddon in mandatoryPrintAddons" :key="printAddon.Id">
                                {{printAddon.Name}}: {{printAddon.Quantity}} x {{printAddon.Price}} {{ $globalTexts.productdetailspage_printMandatory}}
                            </div>
                        </div>
                    </div>

                    <div class="_productDetails-printsWrapper">
                        <a @click="currentPrintConfigState = 'selectPosition'" class="_button _productDetails-printsButton" data-cy="pdp-printdialog-step1-continue-button">{{ $globalTexts.productdetailspage_printbuttoncontinue}}</a>
                    </div>
                </div>

                <div class="_productDetails-printsSettingsContainer" v-if="currentPrintConfigState === 'selectPosition'">

                    <div class="_productDetails-printsWrapper">
                        <div class="_productDetails-printsTitle">
                            {{ $globalTexts.productdetailspage_printmethod }}
                        </div>

                        <div class="_layout-flex">
                            <span class="_productDetails-printsSelectedPrint">{{selectedPrintDataSourceType}} - {{selectedPrintGroup.Name}} - {{selectedPrintType.Name}}</span>
                            <a @click="currentPrintConfigState = 'selectPrintVariant'" class="_button _productDetails-printsButtonSmall">{{ $globalTexts.productdetailspage_printchange }}</a>
                        </div>
                    </div>

                    <div class="_productDetails-printsWrapper">
                        <div class="_productDetails-printsTitle">
                            {{ $globalTexts.productdetailspage_selectprintposition }}
                        </div>

                        <div v-for="printPosition in selectedPrintGroup.PrintPositions" :key="printPosition.UniqueId">
                            <div v-if="selectedPrintPosition == printPosition">
                                <input type="radio" name="printPosition" :id="printPosition.UniqueId" checked="checked" @click="selectedPrintPosition = printPosition" />
                                <label class="_productDetails-printsLabel" :for="printPosition.UniqueId">{{printPosition.Name}}</label>
                            </div>
                            <div v-else>
                                <input type="radio" name="printPosition" :id="printPosition.UniqueId" @click="selectedPrintPosition = printPosition" />
                                <label class="_productDetails-printsLabel" :for="printPosition.UniqueId">{{printPosition.Name}}</label>
                            </div>
                        </div>

                    </div>

                    <div class="_layout-flex _productDetails-printsWrapper" v-if="selectedPrintType != null && selectedPrintType.DataSource === 'Image'">
                        <div class="_productDetails-printsTitle">
                            {{ $globalTexts.productdetailspage_printimage }}
                        </div>

                        <a class="_productDetails-printsBox" :class="{'_productDetails-printsBoxSelected' : selectedPrintImage != null}" @click="showMediaPopup()">
                            <div v-if="selectedPrintImage == null" :title="$globalTexts.productdetailspage_chooseimage">{{ $globalTexts.productdetailspage_chooseimage }}</div>
                            <img class="_productDetails-printsImagePreview" :src="selectedPrintImage.Url" v-if="selectedPrintImage != null" />
                        </a>
                    </div>

                    <div class="_layout-flex _productDetails-printsWrapper" v-if="selectedPrintType != null && selectedPrintType.DataSource === 'Text'">
                        <div class="_productDetails-printsTitle">
                            {{ $globalTexts.productdetailspage_printtext }}
                        </div>

                        <input type="text" class="_productDetails-printsText" v-model="selectedPrintText" :maxlength="selectedPrintType.TextMaxLength" v-if="selectedPrintType.TextMaxLength > 0" data-cy="pdp-printdialog-text-input" />
                        <input type="text" class="_productDetails-printsText" v-model="selectedPrintText" v-if="selectedPrintType.TextMaxLength === 0" />
                    </div>

                    <div class="_productDetails-printsWrapper" v-if="selectedPrintType.PrintAddOns != null && selectedPrintType.PrintAddOns.filter(a=>!a.Mandatory).length > 0">
                        <div class="_productDetails-printsTitle">
                            {{ $globalTexts.productdetailspage_printaddon }}
                        </div>

                        <div v-for="printAddon in selectedPrintType.PrintAddOns.filter(a=>!a.Mandatory)" :key="printAddon.Id">
                            <div v-if="printAddon.IsSelected">
                                <input type="checkbox" :id="printAddon.Id" checked="checked" @click="printAddon.IsSelected = false" />
                                <label class="_productDetails-printsLabel" :for="printAddon.Id">{{printAddon.Name}}: {{printAddon.Price}}</label>
                            </div>
                            <div v-else>
                                <input type="checkbox" :id="printAddon.Id" @click="printAddon.IsSelected = true" />
                                <label class="_productDetails-printsLabel" :for="printAddon.Id">{{printAddon.Name}}: {{printAddon.Price}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="_productDetails-printsWrapper"  v-if="selectedPrintType != null">
                      <div class="_productDetails-printsTitle">
                        {{ $globalTexts.global__quantity }}:
                      </div>

                      <text-input
                        type="number"
                        class="quantity-input__input"
                        v-model="selectedQuantity"
                        error-message=""
                        :min="minQuantity"
                        :autoselect="false"
                        :show-error-message="false"
                      />
                    </div>

                    <div class="_productDetails-printsWrapper">
                        <a @click="hidePrintConfigurationPopup" class="_button _productDetails-printsButton">{{ $globalTexts.productdetailspage_printbuttoncancel }}</a>
                        <a v-if="selectedPrintImage === null && (selectedPrintText === null || selectedPrintText === '')" class="_button _productDetails-printsButton" disabled="disabled">{{ $globalTexts.productdetailspage_printbuttoncontinue }}</a>
                        <a v-if="selectedPrintImage !== null || (selectedPrintText !== null && selectedPrintText !== '')" @click="selectPrint" class="_button _productDetails-printsButton" data-cy="pdp-printdialog-step2-continue-button">{{ $globalTexts.productdetailspage_printbuttoncontinue }}</a>
                    </div>

                </div>
            </div>
        </div>

        <!-- Popup Select Image -->
        <div class="_productDetails-printsPopupContent _productDetails-printsPopupContentImages" v-if="currentPrintConfigState === 'selectImage'">
            <div class="_productDetails-printsPopupHeading">
                <span>{{productPrintsErrorMessage}}</span>
                <span class="_productDetails-printsCancel" @click="hideMediaPopup">×</span>
            </div>

            <div class="_productDetails-printsSettingsSection">
                <div class="_layout-flex">
                    <div class="_productDetails-printsImageItem" v-for="image in printImages" :key="image.ThumbnailUrl">
                        <div class="_productDetails-printsImageItemBorder">
                            <div class="_productDetails-printsImageContainer" @click="chooseImage(image)" :title="$globalTexts.productdetailspage_choose">
                                <div class="_productDetails-printsImage" :style="{ backgroundImage: 'url(' + image.ThumbnailUrl + ')' }"></div>
                            </div>
                            <span class="_productDetails-printsRemoveImage" @click="deleteImage(image.SystemId)">{{ $globalTexts.productdetailspage_removeimage }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="_productDetails-printsUploadContainer" :class="{'_productDetails-printsUploadContainerEmpty': printImages.length === 0}">
                <input id="file-upload" type="file" class="_productDetails-printsFileUploadInput" @change="onFileUploadChanged" />
                <label class="_button" for="file-upload">{{ $globalTexts.productdetailspage_uploadimage }}</label>
            </div>
        </div>
    </div>
  </teleport>
</template>

<style>
.print-radio-button {
  display: flex;
  position: relative;
  padding-left: 1.75rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  margin: 0;
  padding-bottom: 0.5rem;
}

.print-radio-button__label {
  font-size: 14px;
  white-space: nowrap;
  color: #1a1a1a;
  font-weight: 600;
  line-height: 19px;
}

.print-radio-button__input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.print-radio-button__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: rgba(184, 204, 214, 0.25);
  border-radius: 50%;
  box-shadow: inset 1px 2px 2px rgba(109, 109, 142, 0.15);
}

a._button._productDetails-printsButton {
  text-transform: capitalize;
}

.print-radio-button__input:checked ~ .radio-button__checkmark {
  border-color: #333333;
}

.print-radio-button__checkmark-icon {
  display: none;
  background-color: white;
  height: 6px;
  width: 6px;
  padding: 2px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
}

.print-radio-button__description {
  font-size: 13px;
  white-space: nowrap;
  color: #797b80;
  padding: 0.5rem 0 0 1.75rem;
}

.print-radio-button__input:checked ~ .print-radio-button__checkmark {
  background-color: #333333;
}
.print-radio-button__input:checked
  ~ .print-radio-button__checkmark
  .print-radio-button__checkmark-icon {
  display: inline;
}

._layout-flex {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: start;
    flex-flow: row wrap;
}

._productDetails-printsPopup input {
  margin-right: 3px;
}

._button {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color .2s;
    cursor: pointer;
}

label._button {
  background-color: #333;
  color: white;
  padding: 8px 30px;
}

a._button._productDetails-printsButton {
    display: inline-block;
    width: 200px;
    height: 40px;
    padding: 8px 16px;
    color: var(--color-grey150);
    background-color: var(--color-white);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-oceanFog);
}

a._button._productDetails-printsButton:not(:first-child) {
  margin-left: 3px;
}

a._button._productDetails-printsButton[disabled] {
    opacity: 0.4;
}

a._button._productDetails-printsButton[disabled]:hover {
    color: var(--color-grey150);
    background-color: var(--color-white);
}

a._button._productDetails-printsButton:hover {
    background-color: #444;
    color: #fff;
}

.fas._productDetails-printsButtonIconAdd {
    float: right;
    line-height: inherit;
}

._productDetails-printsPopup {
    position: fixed;
    z-index: 600;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
}

._productDetails-printsPopupContent {
    margin: 2rem auto;
    padding: 1rem;
    border: 1px solid var(--color-grey500);
    background-color: var(--color-white);
    color: var(--color-black);
    max-width: 1440px;
    right: 0;
    left: 0;
    position: absolute;
}

._productDetails-printsPreviewImageContainer {
    width: 50%;
    padding-right: 1rem;
}

._productDetails-printsSettingsContainer {
    width: 50%;
}

._productDetails-printsWrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: .8rem;
    padding: 1rem 0;
}

._productDetails-printsTitle {
    width: 100%;
    font-size: 14px;
    margin: 0 0 10px;
    font-weight: 700;
}

._productDetails-printsBox {
    text-align: center;
    width: 6rem;
    height: 6rem;

    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    border-width: 1px;
    border-style: solid;

    cursor: pointer;
    flex-direction: column;
    position: relative;
}

._productDetails-printDataSourceType {
    background-color: green;
    font-size: .8rem;
    border: 1px solid var(--color-oceanFog);
    padding: 8px 30px;
    color: var(--color-grey200);
    background-color: var(--color-white);
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 1px;
    transition: background-color .2s;
    user-select: none;
    cursor: pointer;
}

._productDetails-printSelectedDataSourceType {
    background-color: var(--color-grey150);
    color: var(--color-white);
    cursor: default;
}

._productDetails-printDisabledDataSourceType {
    color: var(--color-grey200);
    border-color: #333;
    opacity: 0.4;
    cursor: default;
}

._productDetails-printsBoxSelected {
    opacity: 1;
}

._productDetails-printsLabel[disabled="true"] {
    opacity: 0.4;
}

._productDetails-printsLabel{
  font-size: 14px;
  cursor: pointer;
}

._productDetails-printsVariantSelect {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-oceanFog);
    width: 50%;
    max-width: 340px;
}

._productDetails-printsSelectedPrint {
    line-height: 28px;
}

a._button._productDetails-printsButtonSmall {
    display: block;
    width: 100px;
    height: 28px;
    padding: 2px 6px;
    color: var(--color-grey150);
    background-color: var(--color-white);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-oceanFog);
    margin: 0 1rem;
}

._productDetails-printsPrices {
    border: 1px solid #E7E6E6
;
    border-collapse: collapse;
    border-spacing: 0;
}

._productDetails-printsPriceHeader th {
    padding: 0.2rem .5rem;
    background: #333333;
    color: #FFFFFF;
    border: solid 1px #E7E6E6
;
}

._productDetails-printsPriceRow > td {
    padding: 0.2rem .5rem;
    border: solid 1px #E7E6E6
;
}

._productDetails-printsPriceRow:nth-child(2n) {
    background: #F6F6F6;
}

._productDetails-printPositionImageContainer {
    margin: auto;
}

._productDetails-printPositionImageRectangle {
    position: absolute;
    border: 1px solid var(--color-black);
    outline: 1px solid var(--color-white);
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

._productDetails-printPositionImageRectangleText {
    color: var(--color-white);
    font-size: 1rem;
    white-space: nowrap;
}

._productDetails-printPositionImage {
    margin: auto;
    width: 500px;
    max-width: 100%;
    display: block;
}

._productDetails-printInvisible {
    visibility: hidden;
}

._productDetails-printsPopupContentImages {
    height: 60%;
}

._productDetails-printsCancel {
    font-size: 45px;
    cursor: pointer;
    vertical-align: top;
    float: right;
    width: 30px;
}

._productDetails-printsSettingsSection {
    padding: 1.5rem 0;
    max-height: 100%;
    overflow: auto;
}

._productDetails-printsImageItem {
    width: 25%;
    padding: .5rem;
    position: relative;
}

._productDetails-printsImageItemBorder {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-oceanFog);
    padding: 4px;
}

._productDetails-printsImageContainer {
    width: 100%;
    position: relative;
    padding-top: 90%;
    cursor: pointer;
}

._productDetails-printsImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

._productDetails-printsRemoveImage {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: none;
    bottom: 8px;
    right: 12px;
}

._productDetails-printsImageContainer:hover, ._productDetails-printsRemoveImage:hover {
    opacity: 0.5;
}

._productDetails-printsImageItem:hover ._productDetails-printsRemoveImage {
    display: inline-block;
}

._productDetails-printsUploadContainer {
    position: absolute;
    bottom: 1rem;
}

._productDetails-printsUploadContainerEmpty {
    top: 50%;
    bottom: auto;
    text-align: center;
    width: 100%;
}

._productDetails-printsFileUploadInput {
    display: none;
}

._productDetails-printsRemovePrint {
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

._productDetails-printsTextPreviewErrorContainer {
    display: flex;
    width: 100%;
    height: calc(100% - 56px);
    justify-content: flex-end;
}

._productDetails-printsTextPreviewErrorIcon {
    align-self: center;
    padding: 10px;
    color: var(--color-red);
}

._productDetails-printsInfo {
    position: absolute;
    top: .5rem;
    right: .5rem;
}

._productDetails-printsImagePreview {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    padding: .25rem;
}

._productDetails-printsText {
    text-align: center;
    color: var(--color-grey250);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-grey500);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-transform: none;
    word-break: break-word;
}

._productDetails-printsImagePreviewBox {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

._productDetails-printsFinalPreview {
    text-align: center;
    width: 47%;
    height: 152px;
    color: var(--color-grey200);
    margin: 5px 0;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-oceanFog);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
}

._productDetails-printsFinalPreviewIncomplete {
    background: rgba(255,0,0,0.4);
}

._productDetails-printsImageContainerPreviewFinal {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
}

._productDetails-printsImagePreviewFinal {
    object-fit: contain;
    margin: 10px;
    max-height: 100%;
}

._productDetails-printsTextPreviewFinal {
    padding-top: 10%;
}

._productDetails-printMandatoryAddonText {
  margin-top: 5px;
}

@media(--phone) {
  a._button._productDetails-printsButton:not(:first-child) {
    margin: 3px 0 0 0;
  }
}
</style>
