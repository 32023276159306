<script>
import TextInput from './forms/TextInput.vue';

export default {
  components: {
    TextInput,
  },
  props: {
    quantity: {
      type: Number,
    },
    packagingInfo: {
      type: String,
    },
    packQuantity: {
      type: Number,
      default: 1,
    },
    unitOfMeasurement: {
      type: String,
    },
    stockItems: {
      type: Array,
    },
    narrowStyle: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
  },
  data: () => ({
    quantityModel: 0,
    errorModel: '',
    changeQuantityTimeout: null,
  }),
  emits: ['update:quantity'],
  computed: {
    stockUnit() {
      return this.unitOfMeasurement || '';
    },
    allStockItems() {
      const stockItems = [...this.stockItems] || [];
      stockItems.sort((a, b) => (a.date > b.date) - (a.date < b.date));
      stockItems.forEach((s) => (s.isActive = false));
      stockItems.some((s) => {
        if (s.amount >= this.quantityModel) {
          return (s.isActive = true);
        }
        if (s.hasInfiniteStock) {
          return (s.isActive = true);
        }
      });

      return stockItems;
    },
  },
  methods: {
    setAmount(amount) {
      const { error } = this;
      this.quantityModel = amount;
      this.$nextTick(() => {
        this.errorModel = error;
      });
    },
  },
  watch: {
    error() {
      this.errorModel = this.error;
    },
    quantity() {
      if (this.quantity && this.quantityModel != this.quantity) {
        this.quantityModel = this.quantity;
      }
    },
  },
  created() {
    this.quantityModel = this.quantity;
    this.errorModel = this.error;

    this.$watch(
      () => this.quantityModel,
      (newValue) => {
        if (this.quantityModel == this.quantity) {
          return;
        }
        this.errorModel = '';

        if (this.changeQuantityTimeout) {
          clearTimeout(this.changeQuantityTimeout);
        }
        this.changeQuantityTimeout = setTimeout(() => {
          this.$emit('update:quantity', newValue);
        }, 300);
      }
    );
  },
};
</script>

<template>
  <div
    class="quantity-input"
    :class="{ 'quantity-input--narrow': narrowStyle }"
  >
    <div class="quantity-input__quantity-wrapper">
      <text-input
        type="number"
        class="quantity-input__input"
        v-model="quantityModel"
        :min="1"
        :autoselect="true"
        :error-message="errorModel"
        :show-error-message="false"
      />
      <div class="quantity-input__package-info" v-if="packagingInfo">
        {{ packagingInfo }}
      </div>
    </div>
    <div class="quantity-input__stock-wrapper">
      <div
        class="quantity-input__stock-item"
        :class="{ 'quantity-input__stock-item--active': stockItem.isActive }"
        v-for="stockItem in allStockItems"
        :key="stockItem.systemId"
      >
        <img
          class="quantity-input__stock-item-checkmark"
          src="../static/icons/stock_checkmark_icon.svg"
          v-if="stockItem.isActive || stockItem.hasInfiniteStock"
        />
        <div>
          <span v-if="!stockItem.hasInfiniteStock"
            >{{ stockItem.stockLabel }}: {{ stockItem.amount }}
            {{ stockUnit }}</span
          >
          <span v-else>{{ stockItem.stockLabel }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-if="errorModel" class="quantity-input_error">
    {{ errorModel }}
  </div>
</template>

<style>
.quantity-input {
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0;
}

.quantity-input__quantity-wrapper {
  display: flex;
  width: 100%;
}

.quantity-input__input {
  width: 35%;
}

.quantity-input__package-info {
  width: 65%;
  font-size: 12px;
  color: #5379ff;
  line-height: 15px;
  padding-left: 0.75rem;
}

.quantity-input__stock-wrapper {
  margin-top: 0.75rem;
}

.quantity-input__stock-item {
  padding: 0.25rem 0.75rem;
  color: #1a1a1a;
  line-height: 22px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: default;
}

.quantity-input__stock-item--active {
  background-color: #459a33;
  color: #fff;
}

.quantity-input__stock-item-checkmark {
  padding-right: 0.35rem;
}

.quantity-input_error {
  text-align: center;
  margin-bottom: 0.5rem;
  color: var(--color-feedback-error);
  font-size: 12px;
}

@media (--tabletAndDesktop) {
  .quantity-input:not(.quantity-input--narrow) {
    flex-direction: row;
  }

  .quantity-input:not(.quantity-input--narrow)
    .quantity-input__quantity-wrapper {
    flex-direction: column;
    width: 36%;
  }

  .quantity-input:not(.quantity-input--narrow) .quantity-input__stock-wrapper {
    width: 64%;
    margin-top: 0;
  }

  .quantity-input:not(.quantity-input--narrow) .quantity-input__input,
  .quantity-input:not(.quantity-input--narrow) .quantity-input__package-info {
    width: 100%;
  }

  .quantity-input:not(.quantity-input--narrow) .quantity-input__package-info {
    padding-left: 0;
    padding-top: 0.5rem;
  }

  .quantity-input:not(.quantity-input--narrow) .quantity-input__stock-item {
    padding-left: 0.75rem;
    margin-left: 0.5rem;
    font-size: 12px;
  }
}
</style>
