<script>
import { defineComponent } from 'vue';
import { navigateKey } from '@drapejs/core';

export default defineComponent({
  inject: {
    $addToCartContextReactive: '$addToCartContextReactive',
    navigate: navigateKey,
  },
  computed: {
    selectedVariant() {
      return this.$addToCartContextReactive.selectedVariant;
    },
    colorSizes() {
      return this.$addToCartContextReactive.colorSizes;
    },
    product() {
      return this.$addToCartContextReactive.product;
    },
  },
  methods: {
    setSelectedSize(e) {
      const articleNumber = e.target.value;
      const variant = this.colorSizes.filter(
        (f) => f.articleNumber === articleNumber
      )?.[0];
      if (variant) {
        this.navigate(this.$toVariantUrl(variant), { replaceHistory: true });
      }
    },
    isCurrentSize(size) {
      return this.selectedVariant?.articleNumber === size.articleNumber;
    },
    getSizeName(size) {
      return size.fields.Size?.name;
    },
  },
});
</script>

<template>
  <div class="add-to-cart-button">
    <select v-if="colorSizes.length > 1" @change="setSelectedSize">
      <option
        v-for="size in colorSizes"
        :key="size.articleNumber"
        :selected="isCurrentSize(size)"
        :value="size.articleNumber"
      >
        {{ getSizeName(size) }}
      </option>
    </select>

    <button
      class="primary-button"
      @click="$addToCartContextReactive.openAddToCartModal()"
    >
      {{ $globalTexts.global__add_to_cart }}
    </button>
  </div>
</template>

<style>
.add-to-cart-button {
  display: flex;
  flex-direction: column;
}

.add-to-cart-button select {
  width: 100%;
  margin-bottom: 10px;
  user-select: none;
}

.add-to-cart-button button {
  flex-grow: 1;
}

@media (--tabletAndDesktop) {
  .add-to-cart-button {
    flex-direction: row;
  }

  .add-to-cart-button select {
    margin-right: 10px;
    margin-bottom: 0px;
    width: 40%;
  }
}
</style>
