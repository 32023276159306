<script>
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['$addToCartContextReactive'],
  computed: {
    description() {
      return this.getFieldValueWithFallback('_description');
    },
    usps() {
      const usps = this.getFieldValueWithFallback('USPs') || '';
      return usps.replace(/\n/g, '<br>');
    },
    selectedVariant() {
      return this.$addToCartContextReactive.selectedVariant;
    },
    product() {
      return this.$addToCartContextReactive.product;
    }
  },
  methods: {
    getFieldValueWithFallback(fieldId) {
      let value = this.selectedVariant.fields[fieldId];
      if (value === null || typeof value === 'undefined') {
        value = this.product.fields[fieldId];
      }
      return value;
    }
  }
});
</script>

<template>
  <div class="product-details__description text-editor-content">
    <div v-html="description"></div>
    <div v-if="usps" class="product-details__usps" v-html="usps"></div>
  </div>
</template>

<style>
.product-details__description {
  font-size: 16px;
  line-height: 150%;
}

.product-details__usps {
  margin-top: 26px;
}

@media (--tabletAndDesktop) {
  .product-details__description {
    line-height: 170%;
  }
}
</style>
