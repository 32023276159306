<script>
import { defineComponent, provide } from 'vue';
import { fetchPageBuilder } from '@drapejs/core';
import * as sales from '../../../connectors/litium/sales.ts';
import AddToCartButton from '../AddToCartButton.vue';
import ContentCarousel from '../ContentCarousel.vue';
import SlideDots from '../SlideDots.vue';
import ColorPicker from '../ColorPicker';
import { getParent } from '../../helpers/dom-traversing';
import ProductOverviewStockStatus from './ProductOverviewStockStatus.vue';
import ProductOverviewUsps from './ProductOverviewUsps.vue';
import PersonalizeProductButton from '../PrintModule/PersonalizeProductButton.vue';
import BackInStock from '../BackInStock/BackInStock.vue';
import ProductConfigurator from '../ProductConfigurator/ProductConfigurator.vue';
import ProductDetailsImageDownloader from '../ProductDetails/ProductDetailsImageDownloader.vue';
import useContext from '@/composables/useContext';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default defineComponent({
  components: {
    AddToCartButton,
    ContentCarousel,
    SlideDots,
    ColorPicker,
    ProductOverviewStockStatus,
    ProductOverviewUsps,
    PersonalizeProductButton,
    BackInStock,
    ProductConfigurator,
    ProductDetailsImageDownloader
  },
  inject: ['$addToCartContextReactive'],
  data() {
    return {
      currentSlide: 0,
      isAddToCartOpen: false,
      imagesFilter: null
    };
  },
  setup() {
    const { isAuthorized, channel } = useContext();
    const { websiteText } = useWebsiteTexts();
    return {
      isAuthorized,
      channel,
      websiteText
    };
  },
  computed: {
    isAuthenticated() {
      return this.$user?.isAuthenticated || false;
    },
    productName() {
      return this.selectedVariant?.fields?._name || this.product?.fields?._name;
    },
    mainCategory() {
      var breadCrumbs = this.selectedVariant?.breadcrumbs;
      var last = breadCrumbs.filter((b) => !!b.url).pop();
      return last;
    },
    product() {
      return this.$page.dataJson?.dataJson?.product;
    },
    productSystemId() {
      return this.$page.dataJson?.dataJson?.product?.systemId;
    },
    variantLinks() {
      return (
        this.product?.otherVariants.map((v) => this.$toVariantUrl(v)) || []
      );
    },
    selectedVariant() {
      return this.$addToCartContextReactive.selectedVariant;
    },
    selectedColor() {
      return this.$variantToColorString(this.selectedVariant);
    },
    colors() {
      return this.$addToCartContextReactive.productColors;
    },
    isGiftCard() {
      return this.$page.dataJson?.dataJson?.product?.isGiftcard;
    },
    items() {
      const items = this.images;

      const firstVideo = this.videos[0];
      if (firstVideo) {
        items.splice(1, 0, firstVideo);
      }

      const restVideos = this.videos.slice(1);
      return [...items, ...restVideos];
    },
    itemDotSettings() {
      return this.items.map((i) => (i.type === 'video' ? 'v' : 'i'));
    },
    giftCardImages() {
      const allImages = this.selectedVariant?.images || [];
      const f = this.imagesFilter;
      const filteredImages = allImages.filter((i) => {
        return !f || i?.filename?.includes(f);
      });
      if (filteredImages.length == 0) {
        return allImages.filter((i) => i?.filename?.includes('default'));
      }
      return filteredImages;
    },
    productImages() {
      return this.selectedVariant?.images || [];
    },
    images() {
      const images = this.isGiftCard ? this.giftCardImages : this.productImages;
      return images
        .map((i) => ({
          id: i.id,
          type: 'image',
          name: i.filename,
          url: this.$toLitiumMediaUrl(i.id, { maxWidth: 750 })
        }))
        .sort((a, b) => {
          function assignIntBasedOnSuffix(image) {
            const name = image?.name || '';
            if (name.includes('-d')) return 1;
            if (name.includes('-im')) return 2;
            if (name.includes('-f')) return 4;
            return 3;
          }

          return assignIntBasedOnSuffix(a) - assignIntBasedOnSuffix(b);
        });
    },
    videos() {
      const videoList = this.product?.fields?.ProductVideos || [];
      return videoList
        .filter((v) => v.ProductVideoMp4?.id)
        .map((v) => ({
          id: v.ProductVideoMp4.id,
          type: 'video',
          url: this.$toLitiumMediaUrl(v.ProductVideoMp4.id)
        }));
    },
    slideCount() {
      return this.items.length;
    },
    combineGiftCardPriceWithShipping() {
      return (
        this.product.isGiftcard &&
        this.channel.combineGiftCardPriceWithShipping &&
        !this.isAuthorized()
      );
    },
    currentPrice() {
      var price =
        this.selectedVariant?.campaignPrice || this.selectedVariant?.price;
      if (this.combineGiftCardPriceWithShipping) {
        price += this.giftCardFreightPrice;
      }
      return price;
    },
    originalPrice() {
      if (
        this.selectedVariant?.campaignPrice &&
        this.product.isGiftcard &&
        this.combineGiftCardPriceWithShipping
      ) {
        return this.selectedVariant?.originalPrice + this.giftCardFreightPrice;
      }
      return null;
    },
    suggestedRetailPriceText() {
      var price = this.selectedVariant?.originalPrice;
      if (!price) return null;

      if (this.product.isGiftcard && this.combineGiftCardPriceWithShipping) {
        price += this.giftCardFreightPrice;
      }

      return this.$replaceTokens(this.$globalTexts.global__retail_price_label, {
        price: this.$formatPrice(price)
      });
    },
    additionalFeeListing() {
      return (
        this.selectedVariant?.additionalFees.map(
          (fee) => `<span class="product-overview__additional-fee">
            ${fee.name}&nbsp;(${this.$formatPrice(fee.cost)})</span>`
        ) || []
      );
    },
    additonalFeesMessage() {
      const fees = this.additionalFeeListing;
      if (!fees.length) return null;

      return this.$replaceTokens(this.$globalTexts.global__label_fee, {
        fees: fees.join(', ')
      });
    },
    printData() {
      return {
        imageUrl: this.images?.[0]?.url,
        variantSystemId: this.selectedVariant.systemId
      };
    },
    hidePrices() {
      const hideAnonymous =
        this.$channel?.hidePricesForAnonymousUsers &&
        !this.$user?.isAuthenticated;
      const hideFlexible = this.selectedVariant?.fields?.IsFlexibleType;
      return hideAnonymous || hideFlexible;
    },
    isVariantOutOfStock() {
      if (this.selectedVariant.stock.currentStock.amount > 0) {
        return false;
      }

      if (this.selectedVariant.stock.incomingStock.some((s) => s.amount > 0)) {
        return false;
      }

      return true;
    },
    decorations() {
      return this.product?.fields?.Decorations || [];
    },
    addons() {
      return this.product?.fields?.Addons || [];
    },
    allVariants() {
      const allVars =
        this.product?.otherVariants?.filter?.((o) => o.systemId) || [];
      if (this.product?.selectedVariant?.articleNumber) {
        allVars.add(this.product.selectedVariant);
      }
      return allVars;
    },
    giftCardFreightPrice() {
      return this.product?.fields?.GiftCardFreightPrice || [];
    },
    articleNumber() {
      return this.selectedVariant?.articleNumber;
    },
    variantId() {
      return this.selectedVariant?.systemId;
    },
    displayImageDownloader() {
      return this.variantId && this.selectedVariant?.images?.length > 0;
    }
  },
  watch: {
    selectedColor() {
      this.$nextTick(() => {
        this.goToSlide(1);
      });
    },
    items: {
      handler() {
        if (this.$refs.carousel) {
          this.$refs.carousel.loadSlides();
        }
      },
      deep: true
    }
  },
  methods: {
    async addToCart() {
      const articleNumber = this.selectedVariant?.articleNumber;

      if (!articleNumber) return;

      const request = fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query
        },
        ''
      );
      request.articleNumber = articleNumber;
      request.quantity = 1;

      await this.$invoke(sales.commands.addToCart, request);
    },
    previousSlide() {
      if (this.$refs.carousel) {
        this.$refs.carousel.go(-1);
      }
    },
    nextSlide() {
      if (this.$refs.carousel) {
        this.$refs.carousel.go(1);
      }
    },
    goToSlide(targetSlide) {
      if (this.$refs.carousel) {
        this.$refs.carousel.goToSlide(targetSlide);
      }
    },
    slideDidChange(slide) {
      this.currentSlide = slide;
      this.$nextTick(() => {
        const videos = document.querySelectorAll('video');
        if (videos) {
          const media = this.items[slide - 1];
          videos.forEach((v) => {
            v.pause();
            if (v.dataset.id === media.id) {
              if (getParent(v, (el) => el.dataset.currentSlide)) {
                v.play();
              }
            }
          });
        }
      });
    },
    updateImagesFilter(param) {
      this.imagesFilter = param;
    }
  },
  provide() {
    return {
      changeImageKey: this.updateImagesFilter
    };
  }
});
</script>

<template>
  <div class="product-overview">
    <div class="product-overview__media-container-wrap">
      <div class="product-overview__media-container">
        <div class="product-overview__media">
          <content-carousel
            class="product-overview__media-carousel"
            ref="carousel"
            :key-prefix="selectedColor"
            @slide-did-change="slideDidChange"
          >
            <div
              v-for="(item, i) in items"
              :key="i"
              ref="mediaSlide"
              class="product-overview__media-carousel-item-container"
            >
              <div v-if="item.type === 'image'">
                <div
                  class="product-overview__media-carousel-image-spacer"
                ></div>
                <div
                  class="product-overview__media-carousel-image-overlay"
                ></div>
                <img
                  :src="item.url"
                  :draggable="true"
                  class="product-overview__media-carousel-image"
                  style="pointer-events: all"
                />
              </div>
              <div
                v-if="item.type === 'video'"
                class="product-overview__media-carousel-video"
              >
                <video playsinline muted="muted" loop :data-id="item.id">
                  <source :src="item.url" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <template v-slot:overlay>
              <slide-dots
                :slides="slideCount"
                :current-slide="currentSlide"
                :size="6"
                :click-size="22"
                :dot-settings="itemDotSettings"
                @change-slide="goToSlide"
              />
            </template>
          </content-carousel>
        </div>
        <div
          v-if="slideCount > 1"
          class="product-overview__carousel-thumb-nav product-overview__carousel-thumb-nav--left"
          @click="previousSlide"
        >
          <img
            class="product-overview__arrow product-overview__arrow--left"
            src="@/static/icons/chevron_right_icon.svg"
          />
        </div>
        <div
          v-if="slideCount > 1"
          class="product-overview__carousel-thumb-nav"
          @click="nextSlide"
        >
          <img
            class="product-overview__arrow"
            src="@/static/icons/chevron_right_icon.svg"
            :draggable="false"
          />
        </div>
        <product-details-image-downloader
          v-if="displayImageDownloader"
          :variant-id="variantId"
          :article-number="articleNumber"
        />
      </div>
    </div>

    <div class="product-overview__info">
      <div class="product-overview__main-category">
        {{ websiteText('product_overview__main_category').value }}
        <a
          class="product-overview__main-category-link"
          :href="mainCategory.url"
        >
          {{ mainCategory.title }}
        </a>
      </div>
      <div class="product-overview__name-price">
        <div>
          {{ productName }}
        </div>
        <div
          :class="[
            'product-overview__price',
            { 'product-overview__price-campaign': originalPrice }
          ]"
          v-if="!hidePrices"
        >
          {{ $formatPrice(currentPrice) }}
          <div v-if="originalPrice" class="product-overview__original-price">
            {{ $formatPrice(originalPrice) }}
          </div>
          <div
            v-if="suggestedRetailPriceText"
            class="product-overview__retail-price"
          >
            {{ suggestedRetailPriceText }}
          </div>
        </div>
      </div>
      <color-picker
        class="product-overview__color-picker"
        v-if="colors.length > 1"
      />
      <ProductConfigurator
        :decorations="decorations"
        :addons="addons"
        :variants="allVariants"
        :baseProductSystemId="productSystemId"
        :selectedVariant="selectedVariant"
        :isGiftCard="isGiftCard"
        :giftCardFreightPrice="giftCardFreightPrice"
        @updateImagesFilter="updateImagesFilter"
      ></ProductConfigurator>
    </div>
  </div>
</template>

<style>
.product-overview {
  display: flex;
  width: 100%;
  max-width: calc(var(--header-max-width) + 30px);
  margin: 0 auto 30px;
}

.product-overview__media-carousel-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-overview__media-carousel,
.product-overview__media-carousel .content-carousel,
.product-overview__media-carousel .content-carousel__slide,
.product-overview__media-carousel-item-container {
  height: 100% !important;
}

.product-overview__media-carousel-image-spacer {
  padding-top: 130%;
}

.product-overview__media-carousel-image-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
  pointer-events: none;
}

.product-overview__media-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: auto;
}

.product-overview__media-carousel-video {
  height: 100% !important;
}

.product-overview__media-carousel-video {
  height: 100%;
  width: 100%;
}

.product-overview__media-carousel-video > video {
  object-fit: contain;
  object-position: center center;
  display: block;
  width: 99.9999%;
  height: 100%;
}

.product-overview__media-carousel .slide-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.product-overview {
  flex-direction: column;
}

.product-overview__media-container {
  position: sticky;
  width: 100%;
  padding-top: 129.8275862068966%;
  top: 75px;
  margin-bottom: 69px;
}

.product-overview__media {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.product-overview__carousel-thumb-nav {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 80px;
  width: 80px;
  right: 0;
  top: calc(50% - 40px);
  user-select: none;
}

.product-overview__carousel-thumb-nav--left {
  left: 0;
}

.product-overview__arrow {
  height: 16px;
}

.product-overview__arrow--left {
  transform: rotate(180deg) translateX(1px);
  left: 0;
}

.product-overview__additional-fees {
  font-size: 14px;
  line-height: 22px;
  color: #ee2d00;
  margin-top: 10px;
}

.product-overview__additional-fee {
  white-space: nowrap;
}

.product-overview__personalize-product {
  margin-top: 27px;
  padding-bottom: 2px;
  text-decoration: underline;
}

.product-overview__info {
  max-width: 688px;
  padding: 0 10px;
  margin: 0 auto;
  width: 100%;
}

.product-overview__name-price {
  height: 60px;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  gap: 24px;
}

.product-overview__price {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
}

.product-overview__price-campaign {
  color: var(--color-feedback-error);
}

.product-overview__original-price {
  color: var(--color-neutrals-60);
  font-weight: 400;
  white-space: nowrap;
  position: relative;
}

.product-overview__original-price::after {
  content: '';
  position: absolute;
  top: 48%;
  left: -1px;
  right: -1px;
  border-top: 3px solid var(--color-neutrals-60);
}

.product-overview__retail-price {
  position: absolute;
  top: calc(100% - 30px);
  right: 0;
  font-size: 12px;
  color: #6d6d8e;
  font-weight: 400;
  white-space: nowrap;
}

.product-overview__main-category {
  margin-top: 1rem;
  font-size: 12px;
}

.product-overview__main-category-link {
  text-decoration: underline;
  font-size: 12px;
}

@media (--tabletAndDesktop) {
  .product-overview__media-container-wrap {
    width: 512px;
    flex-shrink: 0;
  }

  .product-overview__add-to-cart-button {
    width: 100%;
  }

  .product-overview {
    flex-direction: row;
    padding: 0 15px;
  }

  .product-overview__carousel-thumb-nav {
    display: flex;
  }

  .product-overview__color-picker {
    margin-bottom: 20px;
  }

  .product-overview__info {
    padding: 0 0 0 40px;
  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .product-overview__media-container-wrap {
    width: 46%;
  }
}

@media (--desktop) {
  .product-overview__info {
    margin: 0;
  }
}
</style>
