<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    usps: {
      type: Array,
    },
  },
  computed: {
    uspList() {
      if (!this.usps) return [];
      return this.usps;
    },
  },
});
</script>

<template>
  <div class="product-overview-usps">
    <div class="product-overview-usps__item" v-for="usp in uspList" :key="usp">
      <img
        class="product-overview-usp__icon"
        src="../../static/icons/check.svg"
      />
      <div class="product-overview-usp__text">{{ usp }}</div>
    </div>
  </div>
</template>

<style>
.product-overview-usps {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.product-overview-usps__item {
  display: flex;
  font-size: 14px;
  color: #000000;
  padding-right: 16px;
  align-items: center;
  padding-bottom: 1rem;
}

.product-overview-usp__icon {
  padding-right: 0.5rem;
  height: 15px;
  padding-top: 3px;
}
</style>