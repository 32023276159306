<script>
export default {
  props: {
    type: {
      type: String,
      default: "simple"
    },
  },
  data: () => ({
    isHovered: false,
  }),
  methods: {
    onMouseEnter() {
      this.isHovered = true;
    },
    onMouseLeave() {
      this.isHovered = false;
    },
  },
};
</script>

<template>
  <div
    class="link-button"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="link-button__text" :class="{'link-button__text--underline': type === 'underline'}">
      <slot />
    </div>
    <template v-if="type === 'arrow'">
      <img
        class="link-button__arrow"
        src="../../static/icons/right_arrow_icon.svg"
        v-show="!isHovered"
      />
      <img
        class="link-button__arrow"
        src="../../static/icons/right_arrow_hover_icon.svg"
        v-show="isHovered"
      />
    </template>
  </div>
</template>

<style>
.link-button {
  display: flex;
  align-items: center;
}

.link-button__text {
  font-size: 16px;
  color: #1a1a1a;
}

.link-button__text:hover {
  color: #7f95a9;
}

.link-button__arrow {
  padding-left: 0.35rem;
}
</style>