<script>
import { defineComponent } from 'vue';
import * as config from '../../config';

export default defineComponent({
  components: {

  },
  inject: ["$addToCartContextReactive"],
  emits: ['add-to-calculation'],
  props: {
    position: {
      type: String,
    },
  },
  data() {
    return {
      selectedMethod : null,
      selectedType: null
    };
  },
  computed: {
    selectedVariant() {
      return this.$addToCartContextReactive.selectedVariant;
    },
    availablePrints() {
      return this.selectedVariant.availablePrints.filter(p=>p.printPositions.filter(pp=>pp.name == this.position).length != 0);
    },
    previewImage() {
      if(this.selectedMethod == null) {
        return null;
      }

      var matchingPositions = this.selectedMethod.printPositions.filter(p=>p.name == this.position);
      if(matchingPositions.length == 0 || matchingPositions[0].images.length == 0) {
        return null;
      }

      return matchingPositions[0].images[0];
    },
    previewImageUrl() {
      return config.litiumBaseUrl + this.previewImage?.url;
    },
    previewBoxStyle() {
      if(!this.previewImage) {
        return {};
      }

      return {
        'top': (this.previewImage.y - this.previewImage.height / 2) + '%',
        'left': (this.previewImage.x - this.previewImage.width / 2) + '%',
        'height': this.previewImage.height + '%',
        'width': this.previewImage.width + '%',
        'transform': 'rotate(' + this.previewImage.rotation + 'deg)'
      }
    }
  },
  methods: {
    isMethodSelected(method) {
      return this.selectedMethod?.id == method.id;
    },
    selectMethod(method) {
      this.selectedMethod = method;
      this.selectType(this.selectedMethod.printTypes[0]);
    },
    formatTypeName(type){
      return type.name.replace(this.selectedMethod.name, '').trim();
    },
    isTypeSelected(type) {
      return this.selectedType?.id == type.id;
    },
    selectType(type) {
        this.selectedType = type;
    },
    canAddToCalculation() {
      return this.selectedMethod && this.selectedType;
    },
    getMandatoryAddons() {
      if(!this.selectedType?.printAddOns) {
        return [];
      }

      return this.selectedType.printAddOns.filter(a=>a.mandatory);
    },
    addToCalculation() {
      this.$emit('add-to-calculation', {
        'position': this.position,
        'method': this.selectedMethod.name,
        'type': this.formatTypeName(this.selectedType),
        'brackets': this.selectedType.priceBrackets,
        'addons': this.getMandatoryAddons()
      });
    }
  },
  watch: {
  },
  mounted() {
    this.selectMethod(this.availablePrints[0]);
  },
});
</script>

<template>
  <div class="personalize-calculator">
    <div class="personalize-calculator__container">
      <div class="personalize-calculator__left">
          <div class="personalize-calculator__title">
            {{ position }}
          </div>

          <div class="personalize-calculator__text">
            {{ $globalTexts.personalization__calculator_instructions }}
          </div>

          <div class="personalize-calculator__text">
            {{ $globalTexts.personalization__calculator_method }}
          </div>
          <div class="personalize-calculator__boxes">
            <button class="personalize-calculator__box"
                  v-for="printMethod in availablePrints"
                  :key="printMethod.id"
                  :class="{'secondary-button': !isMethodSelected(printMethod), 'primary-button': isMethodSelected(printMethod)}"
                  @click="selectMethod(printMethod)">
                  <img v-if="isMethodSelected(printMethod)" src="../../static/icons/checkmark_white_icon.svg" alt="checkmark">
                  {{printMethod.name}}
            </button>
          </div>

          <div class="personalize-calculator__text">
            {{ $globalTexts.personalization__calculator_method_type }}
          </div>
          <div class="personalize-calculator__boxes" v-if="selectedMethod">
            <button class="personalize-calculator__box"
                  v-for="printType in selectedMethod.printTypes"
                  :key="printType.id"
                  :class="{'secondary-button': !isTypeSelected(printType), 'primary-button': isTypeSelected(printType)}"
                  @click="selectType(printType)">
                  <img v-if="isTypeSelected(printType)" src="../../static/icons/checkmark_white_icon.svg" alt="checkmark">
                  {{formatTypeName(printType)}}
            </button>
          </div>
      </div>
      <div class="personalize-calculator__right">
        <div class="personalize-calculator__preview" v-if="previewImageUrl">
            <div class="personalize-calculator__preview-rectangle" :style="previewBoxStyle">
              <div class="personalize-calculator__preview-rectangle-text">Logo</div>
            </div>
            <img :src="previewImageUrl"
                  class="personalize-calculator__preview-image"/>
          </div>
          <div class="personalize-calculator__preview-button-container">
            <button class="primary-button personalize-calculator__preview-button"
                    :disabled="!canAddToCalculation"
                    @click="addToCalculation">
                {{ $globalTexts.personalization__calculator_add }}
            </button>
          </div>
      </div>
    </div>
  </div>
</template>

<style>
  .personalize-calculator {
    display: flex;
    font-style: normal;
  }

  .personalize-calculator__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
  }

  .personalize-calculator__left {
    width: 100%;
  }

  .personalize-calculator__right {
    width: 100%;
  }

  .personalize-calculator__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.588824px;
    color: #1A1A1A;
    padding: 15px 10px 1rem;
    border-bottom: 1px solid #F0F0F0;
    margin: 0 -10px;
  }

  .personalize-calculator__text {
    margin-top: 21px;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }

  .personalize-calculator__line {
    display: flex;
    padding-top: 10px;
    padding-bottom: 7px;
    justify-content: space-between;
    max-width: 300px;
  }

  .personalize-calculator__boxes {
    margin: 6px -10px 30px;
    padding: 0 10px;
    display: flex;
    gap: 10px;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE & Edge */
    scrollbar-width: none; /* Firefox */
  }

  .personalize-calculator__boxes::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  .personalize-calculator__box {
    font-size: 14px;
    white-space: nowrap;
    scroll-snap-align: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .personalize-calculator__box img {
    margin-right: 4px;
  }

  .personalize-calculator__total {
    font-size: 16px;
    line-height: 24px;
  }

  .personalize-calculator__preview {
    width: 100%;
    position: relative;
  }

  .personalize-calculator__preview-rectangle {
    position: absolute;
    border: 1px solid #000000;
    outline: 1px solid #ffffff;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .personalize-calculator__preview-rectangle-text {
    color: #ffffff;
    font-size: 12px;
    user-select: none;
  }

  .personalize-calculator__preview-image {
    max-height: 750px;
    max-width: 100%;
    margin: auto;
    display: block;
  }

  .personalize-calculator__preview-button {
    width: 100%;
  }

  @media (--phone) {
    .personalize-calculator__preview-button-container {
      position: fixed;
      width: calc(100% - 20px);
      z-index: 1;
      padding: 10px 0;
      bottom: 0;
      background: white;
    }
  }

  @media (--tabletAndDesktop) {
    .personalize-calculator {
      max-width: calc(var(--header-max-width) + 30px);
      margin: auto;
      align-items: center;
      height: 100%;
    }

    .personalize-calculator__container {
      flex-direction: row;
      justify-content: center;
    }

    .personalize-calculator__left {
      width: auto;
      min-width: 40%;
    }

    .personalize-calculator__right {
      width: 40%;
    }

    .personalize-calculator__title {
      font-size: 24px;
      line-height: 24px;
      color: #000000;
      border-bottom: none;
      text-align: initial;
    }

    .personalize-calculator__text {
      max-width: 300px
    }

    .personalize-calculator__preview-rectangle-text {
      font-size: 1rem;
    }
  }
</style>
